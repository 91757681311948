<template>
  <div class="manage-cookie-button-container">
    <button
      id="ot-sdk-btn"
      class="button secondary-button manage-cookie-button active ot-sdk-show-settings"
      @click="manageCookie"
    />
  </div>
</template>
<script>
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  name: 'ManageCookieButton',
  components: {
  },
  mounted () {
  },
  methods: {
    manageCookie () {
      console.log('@21')
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'cookiepolicy_managemycookies', 'onclick')
    }
  }
}
</script>
<style lang="scss">
.manage-cookie-button-container {
  background-color: #fff;

  #ot-sdk-btn.manage-cookie-button.ot-sdk-show-settings {
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 27px;
    font-weight: 700;
    border-width: 2px;
    font-size: 14px;
    line-height: 116%;
    color: #E2002C;
    border-color: #E2002C;
  }

  #ot-sdk-btn.manage-cookie-button.ot-sdk-show-settings:active, #ot-sdk-btn.manage-cookie-button.ot-sdk-show-settings:hover {
    background-color: white;
    color: #AF0023;
    border-color: #AF0023;
  }
}

.is-vue-mobile {
  .manage-cookie-button-container {
    #ot-sdk-btn.manage-cookie-button.ot-sdk-show-settings {
      margin-left: 15px;
    }
  }
}

</style>
