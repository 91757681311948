<template>
  <div>
    <transition name="el-zoom-in-center">
      <div v-if="$store.getters['app/getIsHomeAlertShow'] && $store.getters['app/getIsSupportBrowser'] && alertData.length" class="columns is-paddingless is-marginless main-row critical-alert">
        <div class="column is-hidden-mobile" />
        <div class="tips-main-container">
          <div v-if="alertData.length" class="critical-alert-desktop">
            <div class="critical-alert-left">
              <div v-for="(item, index) in alertData" :key="item.id" class="critical-content">
                <div class="critical-item">
                  <img :src="item.img" class="item-image" :screen="item.screen" :width="item.width">
                  <div v-html="item.text" />
                </div>
                <hr v-if="alertData && index < alertData.length - 1">
              </div>
            </div>
            <div>
              <img data-aid="home_alert_close" class="close" src="/img/black_close.png" @click="closeAlert()">
            </div>
          </div>
          <div v-if="alertData.length" class="critical-alert-mobile-contain">
            <agile
              :dots="alertData.length>1"
              class="critical-alert-mobile"
              :autoplay="true"
              :nav-buttons="false"
              :infinite="alertData.length>1"
            >
              <div v-for="item in alertData" :key="item.id" class="slide">
                <img :src="item.img" class="item-image" :screen="item.screen" :width="item.width">
                <div v-html="item.text" />
              </div>
            </agile>
            <img data-aid="home_alert_close" class="close" src="/img/black_close.png" @click="closeAlert()">
          </div>
        </div>
        <div class="column is-hidden-mobile" />
      </div>
    </transition>
    <div class="home-menu">
      <div v-if="isLoginUser" class="hello">{{ $t('headerHelloName') }}{{ userName }}</div>
      <hr v-if="!isMiniProgram()&&isLoginUser">
      <div v-if="!isMiniProgram()" class="menu-contain">
        <div class="menu" :class="menuClass">
          <div class="menu-col" data-aid="home_my_wallet" @click="pushRouterWithQuery($router, { name: 'my-wallet-all' })">
            <img
              v-if="$store.getters['app/isMobile']"
              width="48px"
              srcset="/img/wallet_48.1x.png, /img/wallet_48.2x.png 2x, /img/wallet_48.3x.png 3x"
              src="/img/wallet_48.2x.png"
            >
            <img
              v-else
              width="72px"
              srcset="/img/wallet_72.1x.png, /img/wallet_72.2x.png 2x, /img/wallet_72.3x.png 3x"
              src="/img/wallet_72.2x.png"
            >
            <div>{{ $t('headerMyWalletLow') }}</div>
          </div>
          <div class="line" />
          <div class="menu-col" data-aid="home_my_rewards" @click="pushRouterWithQuery($router, { name: 'my-reward' })">
            <img
              v-if="$store.getters['app/isMobile']"
              width="48px"
              srcset="/img/rewards_48.1x.png, /img/rewards_48.2x.png 2x, /img/rewards_48.3x.png 3x"
              src="/img/rewards_48.2x.png"
            >
            <img
              v-else
              width="72px"
              srcset="/img/rewards_72.1x.png, /img/rewards_72.2x.png 2x, /img/rewards_72.3x.png 3x"
              src="/img/rewards_72.2x.png"
            >
            <div>{{ $t('myWalletMyVouchers') }}</div>
          </div>
          <div class="line" />
          <!--<div class="menu-col">
          <img
            width="48px"
            srcset="/img/sim_48.1x.png, /img/sim_48.2x.png 2x, /img/sim_48.3x.png 3x"
            src="/img/sim_48.2x.png"
          >
          <div>SIM-WIFI</div>
        </div>
        <div class="line" />
        <div class="menu-col">
          <img
            width="48px"
            srcset="/img/Itinerary_48.1x.png, /img/Itinerary_48.2x.png 2x, /img/Itinerary_48.3x.png 3x"
            src="/img/Itinerary_48.2x.png"
          >
          <div>My Itinerary</div>
        </div>
        <div class="menu-col">
          <img
            width="48px"
            srcset="/img/my_collect_48.1x.png, /img/my_collect_48.2x.png 2x, /img/my_collect_48.3x.png 3x"
            src="/img/my_collect_48.2x.png"
          >
          <div>My collect</div>
        </div>-->
          <div class="menu-col" data-aid="home_my_account" @click="pushRouterWithQuery($router, { name: 'my-account' })">
            <img
              v-if="$store.getters['app/isMobile']"
              width="48px"
              srcset="/img/account_48.1x.png, /img/account_48.2x.png 2x, /img/account_48.3x.png 3x"
              src="/img/account_48.2x.png"
            >
            <img
              v-else
              width="72px"
              srcset="/img/account_72.1x.png, /img/account_72.2x.png 2x, /img/account_72.3x.png 3x"
              src="/img/account_72.2x.png"
            >
            <div>{{ $t('headerMyAccountLow') }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isLoginUser" class="welcome-line">
        <div class="welcome-box">
          <div class="welcome-contain">
            <div class="welcome">
              <div class="top">
                <img
                  v-if="!$store.getters['app/isMobile']"
                  width="5px"
                  srcset="/img/welcome_line.1x.png, /img/welcome_line.2x.png 2x, /img/welcome_line.3x.png 3x"
                  src="/img/welcome_line.2x.png"
                >
                <div>
                  <div class="title">{{ $t('wechatLoginWelcom') }}</div>
                  <div class="description">{{ $t('homeSignUp') }}</div>
                  <div v-if="$store.getters['app/isMobile']" class="bt primary-button" data-aid="home_login" @click="login()">{{ $t('wechatLoginSignup') }}</div>
                </div>
              </div>
              <div v-if="!$store.getters['app/isMobile']" class="bt primary-button" data-aid="home_login" @click="login()">{{ $t('wechatLoginSignup') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import { isWechatMiniProgram } from '@/utils/utils'
import { loginSSO } from '@/helpers/http'
import markdownLinkExtractor from 'markdown-link-extractor'
import utilsMixin from '@/mixins/utilsMixin'
export default {
  name: 'HomeMenu',
  components: { agile: VueAgile },
  mixins: [utilsMixin],
  data: function () {
    return {

    }
  },
  computed: {
    menuClass () {
      const menuClass = 'three'
      // console.log('@73', window.$('.menu-contain .menu-col').length)
      return menuClass
    },
    isLoginUser () {
      return localStorage.getItem('refreshToken')
    },
    hasGoodieBag () {
      return this.$store.getters['goodiebagparticipationrecords/hasRecord']
    },
    alertData () {
      const alertData = this.$store.state.app.generalAlerts
      console.log('@77', alertData)
      const displayData = []

      alertData.forEach(item => {
        const img = item.severity_level === 'CRITICAL' ? '/img/criticalAlert.2x.png' : '/img/InformativeAlert.2x.png'
        const screen = item.severity_level ===
          'CRITICAL' ? '/img/criticalAlert.1x.png, /img/criticalAlert.2x.png 2x, /img/criticalAlert.3x.png 3x' : '/img/InformativeAlert.1x.png 1x, /img/InformativeAlert.2x.png 2x, /img/InformativeAlert.3x.png 3x'
        const width = item.severity_level === 'CRITICAL' ? '17px' : '17px'
        let text = item.content

        const links = markdownLinkExtractor(text, true)
        console.log('@87', links)

        links.forEach(link => {
          const { href, text: linkText, raw } = link

          const atag = `<a target='_blank' href='${href}'>${linkText}</a>`
          text = text.replaceAll(raw, atag)
        })

        console.log('@87b', text)
        displayData.push({ text, img, screen, width })
      })

      return displayData
      /*
      return [
        { img: '/img/InformativeAlert.png', text: 'Your well-being and safety is our priority. Find tips on staying healthy and updates on travel advisories.' },
        { img: '/img/criticalAlert.png', text: 'Your well-being and safety is our priority. Find tips on staying healthy.' },
        { img: '/img/criticalAlert.png', text: 'Visuit our COVID-19 hub for current travel advice and more.  ' }
      ]
      */
    }
  },
  methods: {
    login () {
      loginSSO(null, true)
    },
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    closeAlert () {
      this.$store.commit('app/setIsHomeAlertShow', false)
      this.$cookie.set('firstHomeAlertShowed', 'true', null)
    }
  }
}
</script>
<style lang="scss" scoped>
.welcome-contain{
    background: #FFFFFF;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 16px;
}
.welcome-line{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.welcome-box{
  background: #F2F3F6;
  max-width: 1024px;
  padding-top: 32px;
  width: 100%;
}
.menu{
    background: #FFFFFF;
}
.welcome{
    background-image: -webkit-image-set(
        url("/img/mobile_welcome.1x.png") 1x,
        url("/img/mobile_welcome.2x.png") 2x,
        url("/img/mobile_welcome.3x.png") 3x);
    background-image: image-set(
        url("/img/mobile_welcome.1x.png") 1x,
        url("/img/mobile_welcome.2x.png") 2x,
        url("/img/mobile_welcome.3x.png") 3x);
    height: 200px;
    background-repeat: repeat-x;
    background-position: bottom;
    box-shadow: 0px 0px 10px rgba(164, 164, 164, 0.3);
    margin-bottom: 32px;
    padding: 20px;
    border-radius: 16px;
}
.home-menu{
    .bt{
        background: #E2012D;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
.is-vue-mobile .home-menu{
    background-color: #fff;
    .hello{
        font-weight: bold;
        font-size: 20px;
        color: #000000;
        padding: 16px;
    }
    hr{
        margin: 0px;
        margin-left: 16px;
        margin-right: 16px;
        background: rgba(226, 1, 45, 0.2);
        height: 1px;
    }
    .menu{
        padding-top: 15px;
        padding-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
    }
    .menu-col{
        width: 33.3%;
        font-weight: bold;
        font-size: 13px;
        color: #000000;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
            margin: 2px;
        }
    }
    .welcome{
        height: unset;
        .title{
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 12px;
            line-height: 30px;
        }
        .description{
            font-size: 15px;
            color: #333333;
            margin-bottom: 24px;
        }
        .bt{
            border-radius: 25px;
            height: 50px;
            font-size: 18px;
            margin-bottom: 5px;
        }
        .bt:hover{
            background: #AF0023;
        }
    }
}
.is-vue-desktop .home-menu{
    .menu-contain{
        display: flex;
        justify-content: center;
        background: #fff;
    }
    .hello{
        display: none;
    }
    hr{
        display: none;
    }
    .menu{
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .line{
        width: 1px;
        height: 90px;
        background: #CCCCCC;
    }
    .menu-col{
        cursor: pointer;
        transition: color 400ms ease-in-out;
        &:hover {
          color: #E2002C;
        }
    }
    .three{
        width: 900px;
        .menu-col{
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 25px;
            color: #000000;
            &:hover {
              color: #E2002C;
            }
            img{
                margin-right: 15px;
            }
        }
    }
    .four{
        width: 900px;
        .menu-col{
            display: flex;
            flex-flow: column;
            align-items: center;
            font-weight: bold;
            font-size: 25px;
            color: #000000;
            img{
                margin-bottom: 10px;
            }
        }
    }
    .five{
        width: 900px;
        height: 155px;
        .menu-col{
            display: flex;
            flex-flow: column;
            align-items: center;
            font-weight: bold;
            font-size: 20px;
            color: #000000;
            img{
                margin-bottom: 10px;
            }
        }
        .line{
            display: none;
        }
    }
    .welcome-box{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: unset;
    }
    .welcome-contain{
        width: 964px;
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .welcome{
        background-image: -webkit-image-set(
            url("/img/desktop_welcome.1x.png") 1x,
            url("/img/desktop_welcome.2x.png") 2x,
            url("/img/desktop_welcome.3x.png") 3x);
        background-image: image-set(
            url("/img/desktop_welcome.1x.png") 1x,
            url("/img/desktop_welcome.2x.png") 2x,
            url("/img/desktop_welcome.3x.png") 3x);
        height: 440px;
        background-repeat: no-repeat;;
        background-position: bottom;
        box-shadow: 0px 0px 10px rgba(164, 164, 164, 0.3);
        margin: 0px;
        padding: 20px;
        border-radius: 16px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .bt{
            border-radius: 100px;
            height: 57px;
            font-size: 18px;
            min-width: 336px;
            margin-top: 56px;
        }
        .bt:hover{
            background: #AF0023;
        }
    }
    .top{
        display: flex;
        flex-flow: row;
        img{
            margin-right: 20px;
        }
    }
}
.mini .home-menu .hello{
  background: #FAFAFA;
}
.tips-main-container {
  font-weight: 600;
  font-size: 14px;

  .item-image {
    flex-shrink: 0;
  }

}
/deep/ .tips-main-container  a, a:visited, a:hover {
    color: #E2002C;
    text-decoration: underline;
}
.critical-alert-desktop{
    border-radius: 8px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;
    /**box-shadow: 0px 0px 10px rgba(164,164,164,29%);**/
    display: flex;
    flex-flow: row;
    margin-top: 18px;
    margin-bottom: 20px;
    .critical-content{
        display: flex;
        flex-flow: column;
    }
    .critical-item{
        display: flex;
        align-items: center;
        margin-top: 14px;
        margin-bottom: 16px;
    }
    hr{
        margin: 0px;
        margin-left:60px;
        margin-right: 30px;
        background: #DBDBDB;
        height: 1px;
    }
    .critical-alert-left{
        width: 100%;
        img{
          margin-right: 12px;
          margin-left: 26px;
          align-self: center;
        }
    }
    .close{
        margin-right: 20px;
        margin-top: 12px;
        cursor: pointer;
    }
}
.critical-alert-mobile-contain{
  word-break: break-word;
  position: relative;
  .close{
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }
}
.is-vue-mobile{
    .critical-alert{
      display: block;
    }
    .is-hidden-mobile{
      display: none;
    }
    .critical-alert-desktop{
        display: none;
    }
    .agile.critical-alert-mobile{
        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(164,164,164,29%);
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 12px;
        padding-bottom: 10px;
        .slide{
            display: flex;
            flex-flow: row;
            text-align: left;
            padding-left: 18px;
            padding-right: 47px;
            margin-bottom: 6px;
            img{
                margin-top: 3px;
                margin-right: 13px;
                align-self: flex-start;
            }
        }
    }
    .mini .critical-alert{
        position: fixed;
        top: 0px;
        z-index: 2000;  // critical alert
        width: 100%;
    }
}
.is-vue-desktop {
  .tips-main-container {
    width: 1024px ;
  }

  .critical-alert-mobile-contain{
    display: none;
  }
}

.home-menu{
    display: none !important;
}
.document-home .home-menu{
    display: block !important;
}
</style>
<style>
.is-vue-mobile .critical-alert-mobile .agile__dots button{
    width: 6px;
    height: 6px;
}
</style>
