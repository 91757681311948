<template>
  <agile
    v-if="slides && slides.length > 0"
    :dots="slides && slides.length > 1"
    class="agile-banner"
    :autoplay="true"
    :nav-buttons="false"
    :infinite="slides && slides.length > 1"
  >
    <div
      v-for="slide in slides"
      :key="slide.id"
      class="slide"
    >
      <component
        :is="slide.component"
        :to="slide.to"
        :href="slide.href"
        class="agile-banner-img"
        :data-aid="getBannerAid()"
        :data-banner-id="slide.id"
        :style="slide.style"
        :data-wechat-url="slide.wechatMiniProgramUrl"
        @click="!!slide.wechatMiniProgramUrl ? openWechatURL(slide.wechatMiniProgramUrl) : null"
      />
    </div>
  </agile>
</template>
<script>
import { VueAgile } from 'vue-agile'
import { isWechatMiniProgram } from '@/utils/utils'
import utilsMixin from '@/mixins/utilsMixin'
export default {
  name: 'AgileBanner',
  components: {
    agile: VueAgile
  },
  mixins: [utilsMixin],
  props: {
    height: {
      type: [Number],
      description: 'height',
      default: 3
    },
    width: {
      type: [Number],
      description: 'width',
      default: 4
    },
    banners: {
      type: [Array],
      description: 'banners',
      default () {
        return []
      }
    }
  },
  computed: {
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    slides () {
      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      // const { host: currenthost } = window.location
      return this.banners
        .map(({
          id,
          manual_input_url: inputUrl,
          wechat_url: rawWechatUrl,
          [imageField]: imageUrl,
          wechat_app_id: wechatAppId,
          wechat_path: wechatPath
        }) => {
          let url = null
          let wechatUrl = null
          try {
            url = inputUrl ? new URL(inputUrl) : null
            wechatUrl = rawWechatUrl ? new URL(rawWechatUrl) : null
          } catch (error) {
            console.log('fail to parse url', inputUrl)
          }
          const { lang } = this.$route.params
          console.log('slide url', url)
          const to = false
          let href = inputUrl
          let component = 'a'
          let wechatMiniProgramUrl = null
          // const finalUrl = wechatUrl || url

          if (isWechatMiniProgram()) {
            href = false
            if (wechatAppId && wechatPath) {
              component = 'div'
              wechatMiniProgramUrl = '/pages/jump/jump?appId=' +
                encodeURIComponent(wechatAppId) + '&path=' + encodeURIComponent(wechatPath) + '&lang=' + lang
            } else if (wechatUrl) {
              wechatMiniProgramUrl = rawWechatUrl

              /*
              if (wechatUrl.host === currenthost) {
                component = 'router-link'
                to = wechatUrl.pathname
                if (wechatUrl.search) {
                  to += wechatUrl.search
                }
              } else { // external URL
                this.$clipboard(rawWechatUrl)
                this.showLinkCopySuccess()
              }
              */
            }
          }

          return {
            id,
            href,
            component,
            to,
            wechatMiniProgramUrl,
            style: {
              'background-image': `url('${imageUrl}')`,
              'padding-top': `calc(${this.height}/${this.width} * 100%)`
            }
          }
        })
    }
  },
  methods: {
    getBannerAid () {
      let bannerAid = ''
      if (this.$route.name === 'my-reward') {
        bannerAid = 'goodie_bag_myrewards_banner_click'
      } else if (this.$route.name === 'campaign-detail') {
        bannerAid = 'redemption_successfully_banner'
      } else if (this.$route.name === 'acquires-credits-success-page') {
        bannerAid = 'redemption_successfully_banner'
      } else {
        bannerAid = 'home_banner_click'
      }
      return bannerAid
    },
    openWechatURL (wechatMiniProgramUrl) {
      console.log('@49', this.isWechatMiniProgram, wechatMiniProgramUrl)
      if (this.isWechatMiniProgram) {
        if (wechatMiniProgramUrl.startsWith('http')) {
          this.$clipboard(wechatMiniProgramUrl)
          this.showLinkCopySuccess()
        } else {
          window.wx.miniProgram.navigateTo({ url: wechatMiniProgramUrl })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.agile-banner-img{
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}
</style>
<style lang="scss">
.agile-banner .agile__dot button{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(142, 142, 142, 0.5);
}
.agile-banner .agile__dot.agile__dot--current button{
    background: #E2002C;
    box-shadow: 0px 1px 2px rgba(142, 142, 142, 0.5)
}
.is-vue-mobile .agile-banner .agile__dots{
  bottom: -18px
}
.is-vue-mobile .agile-banner .agile__dot button{
    height: 6px;
    width: 6px;
}
.is-vue-desktop .agile-banner .agile__dots{
  bottom: -30px
}
</style>
