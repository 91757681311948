import { i18n } from '@/i18n'

const defaultLang = i18n.locale
const getLangName = function (lang) {
  let translateLang = defaultLang

  if (lang === 'zh-t') {
    translateLang = 'zh-Hant'
  } else if (lang === 'zh-s') {
    translateLang = 'zh-Hans'
  } else if (lang === 'en') {
    translateLang = 'en'
  }

  return translateLang
}

const getLangCode = function (lang) {
  let translateLang = defaultLang
  if (!lang) { return translateLang }

  if (['en', 'zh-t', 'zh-s'].includes(lang)) {
    return lang
  }

  if (lang.toLowerCase() === 'zh-hant') {
    translateLang = 'zh-t'
  } else if (lang.toLowerCase() === 'zh-hans') {
    translateLang = 'zh-s'
  } else if (lang.toLowerCase() === 'en') {
    translateLang = 'en'
  }

  return translateLang
}

/*
// not used any more
const getLangCodeFromWindowLocation = function () {
  const pathname = window.location.pathname || ''
  if (pathname.startsWith('/zh-s')) {
    return 'zh-s'
  }
  if (pathname.startsWith('/zh-t')) {
    return 'zh-t'
  }

  return 'en'
}
*/
export { getLangName, getLangCode }
