<template>
  <transition name="el-fade-in">
    <div
      v-if="visible"
      :style="{
        'right': styleRight,
      }"
      class="el-backtop"
      data-aid="home_footer_back_to_top"
      @click.stop="handleClick"
    >
      <slot>
        <el-icon name="caret-top" />
      </slot>
    </div>
    <div v-else class="back-top-placeholder" />
  </transition>
</template>

<script>
import throttle from 'throttle-debounce/throttle'
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  name: 'Backtop',

  props: {
    visibilityHeight: {
      type: Number,
      default: 200
    },
    target: {
      type: String,
      default: ''
    },
    right: {
      type: Number,
      default: 40
    },
    bottom: {
      type: Number,
      default: 40
    }
  },

  data () {
    return {
      el: null,
      container: null,
      visible: false
    }
  },

  computed: {
    styleBottom () {
      return `${this.bottom}px`
    },
    styleRight () {
      return `${this.right}px`
    }
  },

  mounted () {
    this.init()
    this.throttledScrollHandler = throttle(300, this.onScroll)
    this.container.addEventListener('scroll', this.throttledScrollHandler)
  },

  beforeDestroy () {
    this.container.removeEventListener('scroll', this.throttledScrollHandler)
  },

  methods: {
    init () {
      this.container = document
      this.el = document.documentElement
      if (this.target) {
        this.el = document.querySelector(this.target)
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`)
        }
        this.container = this.el
      }
    },
    onScroll () {
      let scrollTop = this.el.scrollTop
      if (this.isOldVersionBrowser(this.el)) {
        scrollTop = document.body.scrollTop
      }
      this.visible = scrollTop >= this.visibilityHeight
    },
    handleClick (e) {
      this.scrollToTop()
      this.$emit('click', e)
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_footer_back_to_top', 'onclick')
    },
    scrollToTop () {
      const el = this.el
      let step = 0
      const interval = setInterval(() => {
        let scrollTop = el.scrollTop
        if (this.isOldVersionBrowser(el)) {
          scrollTop = document.body.scrollTop
        }
        if (scrollTop <= 0) {
          clearInterval(interval)
          return
        }
        step += 10
        if (this.isOldVersionBrowser(el)) {
          document.body.scrollTop -= step
        } else {
          el.scrollTop -= step
        }
      }, 20)
    },
    isOldVersionBrowser (el) {
      return el === document.documentElement && el.scrollTop === 0 && document.body.scrollTop !== 0
    }
  }
}
</script>
