<template>
  <div
    v-if="!(isShowOutOfStockLabel && campaignSettings.outOfStock)"
    :class="{'coupon-item': true,
             'selectable-card': !isCampaignFull && !isCampaignExpired,
             'is-available': status === 'available',
             'is-unavailable': status === 'unavailable',
             'is-expired': status ==='expired',
             'is-dimmed': isCampaignFull || isCampaignExpired}"
    @click="gotoCouponDetail(item.id)"
  >
    <div
      :class="{'coupon-header': true}"
      :style="{backgroundImage: 'url(' + coverImageUrl + ')'}"
    >
      <div v-if="!isCampaign && couponStatus" :class="['coupon-tag', {'expired-tag': true}]"><div>{{ couponStatus }}</div></div>
      <div v-else-if="needShowRecentlyAdd" class="coupon-tag"><div>{{ $t('myWalletRecentlyAdded') }}</div></div>
      <div v-if="offerLabel && offerLabel.name" class="coupon-offer-labels">
        <div class="coupon-offer-label">
          <img src="@/assets/images/offer-label-shadow.png" class="coupon-offer-label-shadow">
          <div class="coupon-offer-label-content">
            <div class="coupon-offer-label-font">
              <div class="coupon-offer-label-font-item">{{ offerLabel.name }}</div>
            </div>
            <div class="coupon-offer-label-right-image" />
            <!--
            <img src="@/assets/images/offer-label-right.png" class="coupon-offer-label-right-image">
            -->
          </div>
        </div>
      </div>
      <div v-if="logoLabels && logoLabels.length" class="coupon-logo-labels">
        <div v-for="(logoLabel, index) in logoLabels.slice(0,3)" :key="index" class="coupon-logo-label" :style="{backgroundImage: 'url(' + logoLabel.icon_url+ ')'}" />
      </div>
      <div v-if="isShowOutOfStockLabel" class="low-label"><div>{{ $t('campaignOutOf') }}</div></div>
      <div v-else-if="isShowLowStockLabel" class="low-label"><div>{{ $t('campaignLow') }}</div></div>
    </div>
    <div class="coupon-content">
      <!-- <div v-if="!isCampaign && couponStatus" class="coupon-status-label"><div>{{ couponStatus }}</div></div> -->
      <div v-if="!isCampaign">
        <div v-if="isTicket && !couponStatus" class="coupon-ticket-expiry-title">
          <span v-if="$i18n.locale=='zh-s' && getTikcetDayTitle!=$t('myWalletNoExpiryDate')">{{ $t('myWalletEffectiveDatetext') }}<span lang="zh-hk">：</span></span>{{ getTikcetDayTitle }}
        </div>
        <div v-else :class="['coupon-expiry-title', {'is-seven-days-left': isSevenDaysLeft}]">{{ getDayTitle }}</div>
      </div>
      <div v-if="isCampaign" class="coupon-title-top-blank" />
      <div class="coupon-title clamp-3">{{ couponName }}</div>
      <div v-if="isCampaign" class="coupon-title-bottom-blank" />
      <div class="coupon-type-container">
        <div class="coupon-type-tags">
          <div v-if="couponCampaignLabel" class="coupon-type starred">{{ couponCampaignLabel }}</div>
          <div v-if="!isCampaign" class="coupon-type" :class="{'limited-offer':isLimitedOffer,'voucher':isVoucher,'ticket':isTicket}">
            {{ isLimitedOffer?$t('goodiebagLimitedOffers'):isVoucher?$t('goodiebagCashVouchers'): isTicket?$t('myWalletTickets'): $t('myWalletCoupons') }}
          </div>
        </div>
      </div>
    </div>
    <div class="coupon-line" />
    <div class="coupon-merchant-container">
      <div
        class="coupon-merchant-icon"
        :style="brandImageUrl ? {backgroundImage: 'url(' + brandImageUrl+ ')'} : false"
      />
      <div class="coupon-merchant-name clamp-2 toastui-editor-contents" v-html="brandDisplay" />
    </div>
  </div>
</template>
<script>
import { isExpired, isUnavailable, isAvailable } from '@/utils/coupon'
import utilsMixin from '@/mixins/utilsMixin'
import { sendManualAnalytics } from '@/utils/tracking'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'CouponItem',
  mixins: [utilsMixin],
  props: {
    item: {
      type: Object,
      default: null
    },
    isCampaign: {
      type: Boolean,
      default: true
    },
    campaignSettings: {
      type: Object,
      default: null
    }
    // searchKey: {
    //   type: String,
    //   default: null
    // }
  },
  computed: {
    couponStatus () {
      let result = null
      if (!this.isCampaign) {
        if (this.item && this.item.is_used) {
          result = this.$t('myWalletRedeemed')
        } else if (isExpired(this.item)) {
          result = this.$t('myWalletExpired')
        }
      }
      return result
    },
    getDayTitle () {
      if (this.isCampaign) {
        return ''
      }

      let title = ''
      const {
        is_used: isUsed,
        used_at: usedDate,
        effective_date: effectiveDate,
        expiry_date: expiryDate
      } = this.item

      var enformatString = 'DD MMM YYYY'

      if (isUsed) {
        const dateString = usedDate ? this.getDateString(usedDate, enformatString) : ''
        title = this.$t('myWalletRedeemedTime', { dateString })
      } else if (isExpired(this.item)) {
        title = `${this.$t('myWalletExpiryDate')}${this.getDateString(expiryDate, enformatString)}`
      } else if (isUnavailable(this.item)) {
        title = `${this.$t('myWalletStartDate')}${this.getDateString(effectiveDate, enformatString)}`
      } else if (expiryDate) {
        const expiryDateObj = new Date(expiryDate)
        const momentExpiryDate = this.$moment(expiryDateObj)
        const dayStart = new Date()
        dayStart.setHours(0, 0, 0, 0)
        if (momentExpiryDate.diff(dayStart, 'days') === 0) {
          title = this.$t('myWalletLastDay')
        } else if (expiryDateObj > dayStart && momentExpiryDate.diff(dayStart, 'days') < 7) {
          const dayCount = momentExpiryDate.diff(dayStart, 'days') + 1
          title = this.$t('myWalletXDaysLeft', { dayCount })
        } else {
          title = `${this.$t('myWalletExpiryDate')}${this.getDateString(expiryDate, enformatString)}`
        }
      } else {
        title = this.$t('myWalletNoExpiryDate')
      }

      return title
    },
    getTikcetDayTitle () {
      if (this.isCampaign || !this.isTicket) {
        return ''
      }
      let title = ''
      const {
        effective_date: effectiveDate,
        expiry_date: expiryDate
      } = this.item
      var enformatString = 'DD MMM YYYY'

      if (effectiveDate || expiryDate) {
        const startDate = this.getDateString(effectiveDate, enformatString)
        const endDate = this.getDateString(expiryDate, enformatString)

        if (this.$moment(expiryDate).diff(effectiveDate, 'days') === 0) {
          title = this.$t('myWalletEffectiveDateLessOneDay', { endDate })
        } else {
          title = this.$t('myWalletEffectiveDate', { startDate, endDate })
        }
      } else {
        title = this.$t('myWalletNoExpiryDate')
      }
      return title
    },
    isCampaignFull () {
      return this.isCampaign && this.item && this.item.type === 'COUPON_CAMPAIGN' && (!this.item.coupon_campaign_type_coupon_stock || this.isShowOutOfStockLabel)
    },
    isCampaignExpired () {
      console.log('isCampaignExpired', this.isCampaign && this.item.is_expired)
      return this.isCampaign && this.item.is_expired
    },
    offerLabel () {
      let result = null
      if (this.isCampaign) {
        if (this.isCampaignExpired) {
          result = { name: this.$t('myWalletExpired') }
        } else {
          result = this.item.offer_type_label
        }
      }
      // console.log('79----- offer labels: ', result, this.item)
      return result
    },
    logoLabels () {
      let result = null
      if (this.isCampaign && this.item.logo_labels) {
        result = [...this.item.logo_labels].sort((a, b) => b.display_priority - a.display_priority)
      }
      return result
    },
    isSevenDaysLeft () {
      if (this.isCampaign || isExpired(this.item) || this.item.is_used || isUnavailable(this.item)) {
        return false
      }

      let result = false
      const dayStart = new Date()
      dayStart.setHours(0, 0, 0, 0)
      const { expiry_date: expiryDate } = this.item
      if (expiryDate) {
        const expiryDateObj = new Date(expiryDate)
        result = expiryDateObj >= dayStart && this.$moment(expiryDateObj).diff(dayStart, 'days') < 7
      }

      return result
    },

    needShowRecentlyAdd () {
      if (!this.isCampaign) {
        return this.$moment(new Date()).diff(this.item.date_of_acquisition, 'hours') <= 24
      }
      return false
    },
    couponName () {
      let name = ''
      if (this.isCampaign) {
        name = this.item.name
      } else {
        name = this.item.template.name
      }
      return name
    },
    brandName () {
      let name = ''
      let brand = null
      if (this.isCampaign) {
        brand = this.item.brand
      } else {
        brand = this.item.template.brand
      }
      if (brand) {
        name = brand.name
      }

      return name || ''
    },
    brandDescription () {
      let description = ''
      let brand = null
      if (this.isCampaign) {
        brand = this.item.brand
      } else {
        brand = this.item.template.brand
      }
      if (brand) {
        description = brand.description
      }

      return description || ''
    },
    brandDisplay () {
      return this.brandName
    },
    coverImageUrl () {
      let url = '/img/trans1px.png'
      if (this.isCampaign) {
        url = this.item.cover_photo_url
      } else {
        url = this.item.template.cover_photo_url
      }
      return url
    },
    brandImageUrl () {
      let result = ''
      let brand = null
      if (this.isCampaign) {
        brand = this.item.brand
      } else {
        brand = this.item.template.brand
      }
      if (brand && brand.icon_url) {
        result = brand.icon_url
      }

      return result
    },
    status () {
      let result = null
      if (isAvailable(this.item)) {
        result = 'available'
      } else if (isExpired(this.item) || this.item.is_used) {
        result = 'expired'
      } else if (isUnavailable(this.item)) {
        result = 'unavailable'
      }

      return result
    },
    isLimitedOffer () {
      let isLimitedOffer = false
      if (this.item && this.item.template && this.item.template.display_in === 'LIMITED_OFFER_SECTION') {
        isLimitedOffer = true
      }
      return isLimitedOffer
    },
    isVoucher () {
      let isVoucher = false
      if (this.item && this.item.template && this.item.template.display_in === 'VOUCHER_SECTION') {
        isVoucher = true
      }
      return isVoucher
    },
    isTicket () {
      let isTicket = false
      if (this.item && this.item.template && this.item.template.display_in === 'TICKET_SECTION') {
        isTicket = true
      }
      return isTicket
    },
    isStarred () {
      let isStarred = false
      if (this.isCampaign) {
        if (this.item) {
          isStarred = this.item.is_starred
        }
      } else {
        if (this.item && this.item.campaign) {
          isStarred = this.item.campaign.is_starred
        }
      }
      return isStarred
    },
    isShowLowStockLabel () {
      let isShowLowStockLabel = false
      if (this.isCampaign && this.item && this.item.show_low_stock_label) {
        isShowLowStockLabel = this.item.show_low_stock_label
      }
      return isShowLowStockLabel
    },
    isShowOutOfStockLabel () {
      let isShowOutOfStockLabel = false
      if (this.isCampaign && this.item && this.item.show_out_of_stock_label) {
        isShowOutOfStockLabel = this.item.show_out_of_stock_label
      }
      return isShowOutOfStockLabel
    },
    couponCampaignLabel () {
      if (this.item.goodie_bag && this.item.goodie_bag.coupon_label && this.item.goodie_bag.coupon_label.id) {
        return this.item.goodie_bag.coupon_label.name
      }

      if (this.isStarred) {
        return this.$t('campaignSTARRED')
      }

      return null
    }
  },
  mounted () {
    this.$store.commit('app/couponItemLoadingFinish')
  },
  methods: {
    gotoCouponDetail (couponId) {
      // if (this.isCampaign && this.searchKey) {
      //   // track generic_offer_campaign_search_result_click click
      //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_search_result_click', 'onclick')
      // }

      if (this.isCampaignFull || this.isCampaignExpired) {
        return
      }

      if (this.isCampaign && this.item && this.item.is_redirection_campaign) {
        const { landing_page_url: landingPageUrl } = this.item
        this.$clipboard(landingPageUrl)
        this.showLinkCopySuccess()
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_offer_redirection_campaign_click', 'onclick', { 'id': couponId })
        return
      }

      let query = { couponId }
      let path = 'coupon-detail'
      if (this.isCampaign) {
        query = { campaignId: couponId }
        path = 'campaign-detail'
      }
      if (this.$route.name === 'home' && path === 'campaign-detail') {
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'home_offer_campaign_click', 'onclick', { 'id': couponId })
      }
      query['from'] = this.$route.name

      /*
      const { wf } = this.$route.query
      if (wf) {
        query['wf'] = wf
      }
      */
      pushRouterWithQuery(this.$router, { path, query })
    }
  }
}
</script>
<style scoped lang='scss'>
@import "@/assets/coupon-item.scss";
.coupon-offer-label-right-image{
  background-image: -webkit-image-set(
    url("/img/offer-label-right.1x.png") 1x,
    url("/img/offer-label-right.2x.png") 2x,
    url("/img/offer-label-right.3x.png") 3x);
  background-image: image-set(
    url("/img/offer-label-right.1x.png") 1x,
    url("/img/offer-label-right.2x.png") 2x,
    url("/img/offer-label-right.3x.png") 3x);
  background-size: contain;
}
</style>
