<template>
  <div class="explore-goodie-bag-container">
    <img
      v-if="$route.query.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'"
      class="gift-logo"
      srcset="/img/gift-logo.1x.png, /img/gift-logo.2x.png 2x, /img/gift-logo.3x.png 3x"
      src="/img/gift-logo.2x.png"
    >
    <img
      v-else
      class="voucher-logo"
      srcset="/img/voucher-logo.1x.png, /img/voucher-logo.2x.png 2x, /img/voucher-logo.3x.png 3x"
      src="/img/voucher-logo.2x.png"
    >
    <div
      v-if="itemType === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'"
      class="explore-goodie-bag-text"
    >
      {{ quotaName?quotaName:$t('goodiebagExploreLimitedoffers') }}
    </div>
    <div
      v-else
      class="explore-goodie-bag-text"
    >
      {{ creditName?creditName:$t('goodiebagExplorecashvoucher') }}
    </div>
  </div>
</template>

<script>
import { sendManualAnalytics } from '../utils/tracking'
export default {
  name: 'ExploreGoodieBagLabel',
  props: {
    itemType: {
      type: String,
      default: null
    },
    creditName: {
      type: String,
      default: null
    },
    quotaName: {
      type: String,
      default: null
    }
  },
  methods: {
    analyticsMouseOver () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaignlist_info_click', 'onclick')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-vue-desktop  .explore-goodie-bag-container {
  padding-left: 10px;
  padding-right: 10px;

  .explore-goodie-bag-text {
    &:before {
      content: ""
    }
    display: block;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    display: -webkit-box;
  }
  .gift-logo{
    margin-top: 7px;
  }
  .voucher-logo{
    margin-top: 10px;
  }
}

.explore-goodie-bag-container{
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 22px;
  align-items: flex-start;

  .voucher-logo {
    width: 30px;
    height: 25px;
    margin-right: 8.5px;
  }

  .explore-goodie-bag-text {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    word-break: break-word;
  }
  .gift-logo{
    margin-top: 0px;
  }
  .voucher-logo{
    margin-top: 4px;
  }
}
.is-vue-mobile .explore-goodie-bag-text {
  max-width: 100%;
}
</style>
