<template>
  <div class="card wechat-login">
    <div class="from">
      <div class="title">{{ $t('wechatLoginWelcom') }}</div>
      <div class="describe">{{ $t('wechatLoginMessage') }}</div>
      <div class="protocol">
        <el-checkbox v-model="checked" class="checkbox" :text-color="'#000000'" />
        <div class="text">
          {{ $t('wechatLoginDescription') }}
          <router-link :to="$i18nRoute($route, 'policy', null, {type: 'tc'})">{{ $t('wechatLoginT&C') }}</router-link>
          {{ $t('wechatLoginAnd') }}
          <router-link :to="$i18nRoute($route, 'policy', null, {type: 'privacy'})">{{ $t('wechatLoginPolicy') }}</router-link>
        </div>
      </div>
    </div>
    <div class="button-contain">
      <div class="button primary-button" :disabled="!checked" @click="submit()">{{ $t('wechatLoginSignup') }}</div>
    </div>
  </div>
</template>
<script>
//* *********** */ this page is not used any more

// import { getSsoAuthClient } from '@/helpers/http'

export default {
  name: 'WechatLogin',
  data: function () {
    return {
      checked: false
    }
  },
  mounted () {
    this.$store.commit('app/hideFooter')
  },
  beforeDestroy () {
    this.$store.commit('app/showFooter')
  },
  methods: {
    submit () {
      if (!this.checked) {
        return
      }

      // window.open(ssoAuth.code.getUri(), '_self')

      if (!window.wx) {
        alert('Wechat not available!')
      } else {
        const { lang } = this.$route.params

        const query = this.$route.query
        const redirectUrl = query ? query.redirectUrl : ''
        const finalUrl = '/pages/login/login?redirect_url=' +
          encodeURIComponent(redirectUrl) + '&lang=' + encodeURIComponent(lang)
        console.log('@49', finalUrl)
        window.wx.miniProgram.navigateTo({ url: finalUrl })
      }

      // do something
    }
  }
}
</script>
<style scoped lang='scss'>
.is-vue-mobile .card{
    height: 100%;
}
.wechat-login{
    padding-top: 61px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 23px;
    .from{
        padding-left: 4px;
        padding-right: 4px;
    }
    .title{
        font-weight: bold;
        font-size: 22px;
        color: #000000;
        margin-bottom: 38px;
    }
    .describe{
        color: #333333;
        font-size: 15px;
        margin-bottom: 52px;
    }
    .protocol{
        display: flex;
        margin-bottom: 21px;
        padding-left: 3px;
        padding-right: 3px;
        .text{
            margin-left: 10px;
            color: #000000;
            font-size: 14px;
            a{
                color: #E3022E;
                font-weight: 600;
            }
        }
    }
    .checkbox{
      margin-top: 2px;
    }
    .button-contain{
      display: flex;
      .button{
        width: 100%;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        height: 50px;
      }
    }
}
</style>
<style>
.wechat-login .el-checkbox__inner{
    border: 1px solid #61666A;
}
.wechat-login .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #fff;
}
.wechat-login .el-checkbox__inner:after{
    border: 1px solid #E2002C;
    border-left: 0;
    border-top: 0;
}
</style>
