<script>
import { isWechatMiniProgram } from '@/utils/utils'

// import { isWechatMiniProgram, LOCATION_AREA_ID } from '@/utils/utils'
// import axios from 'axios'

export default {
  methods: {
    documentClass () {
      const documentClass = ['document', 'document-' + this.$route.name]
      if (this.isMiniProgram()) {
        documentClass.push('mini')
        if (this.navbarType === 'back_language' || this.navbarType === 'back_language_menu') {
          documentClass.push('mini-back')
        }
      }
      if (this.isOneTrustDisplay) {
        documentClass.push('has-one-trust')
      }
      return documentClass
    },
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    resizeHandler (e) {
      this.$store.commit('app/setWindowWidth', window.innerWidth)
      this.$store.commit('app/setWindowHeight', window.innerHeight)
    // your code for handling resize...
    }

  }

}
</script>
