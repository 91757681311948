<template>
  <div class="cookie-contain">
    <div class="text">
      {{ $t('generalCookieAffix') }}<br>{{ $t('generalCookiePrefix') }}
      <router-link :to="$i18nRoute($route, 'policy', null, {type: 'cookie'})">
        {{ $t('generalCookieSuffix') }}
      </router-link>
    </div>
    <div
      class="got-it primary-button"
      @click="close"
    >
      {{ $t('generalGotIt') }}
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

.is-vue-desktop {
  .cookie-contain{
    height: 60px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    .text{
      width: 500px;
    }
    .got-it{
      font-weight: bold;
      font-size: 13px;
      display: flex;
      align-items: center;
      border-radius: 23.9167px;
      width: 89.83px;
      height:28px;
      justify-content: center;
    }
  }
}

.is-vue-mobile{
  .cookie-contain{
    // height: 162px;
    padding: 20px;
    .got-it{
      font-size: 18px;
      font-weight: bold;
      border-radius: 41px;
      height: 48px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-top: 20px;
    }
  }
}

.cookie-contain{
  background: #EAEAEA;
  .text{
    font-size: 13px;
    align-items: center;
    a{
      text-decoration: underline;
      color: #E7002A;
      font-weight: bold;
      margin-left: 1px;
    }
  }
}
</style>
