import Vue from 'vue'
import Vuex from 'vuex'
import { app } from './app.module'
import { local } from './local.module'
import { auth } from './auth.module'
import { coupons } from './coupons.module'
import { goodiebagparticipationrecords } from './goodiebagparticipationrecords.module'
import { goodiebags } from './goodiebags.module'
import { campaigns } from './campaigns.module'
import { interestpreferences } from './interestpreferences.module'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const authState = createPersistedState({
  paths: ['auth', 'local']
})

export const store = new Vuex.Store({
  modules: {
    app,
    local,
    auth,
    campaigns,
    coupons,
    interestpreferences,
    goodiebagparticipationrecords,
    goodiebags
  },
  plugins: [authState]
})

export default store
