<template>
  <div class="fortune-campaign-container">
    <div class="fortune-campaign-image-container">
      <div class="fortune-campaign-image-color" />
      <div
        class="fortune-campaign-image"
      />
    </div>
    <div>
      <div class="fortune-campaign-content">
        <div class="fortune-campaign-title">{{ $t('campaignFortuneBagcampaign') }}</div>
        <div class="fortune-campaign-tips">
          <div class="fortune-campaign-tip">
            From 5 July to 1 August 2021, any purchases of $60 or above (single purchase) at ctrip/agoda can get the fortune bag. You may have a chance to win:
          </div>
          <div class="fortune-campaign-tip">
            Coupons from: Kee Wah Bakery, Tom Lee Music, Mr Simms Olde Sweet Shoppe, Life Inspiration HK....
          </div>
          <div class="fortune-campaign-tip">
            Please read our <strong>Terms of Use</strong>. If you have any further enquiries, please contact the hotel directly or call our hotline at 2807 6500.
          </div>
        </div>
        <div v-if="isShowGetCouponButton" class="fortune-campaign-buttons">
          <div
            v-if="status === 'active'"
            :class="['fortune-campaign-button', {'disabled': !isDataCollected}]"
            @click="isDataCollected && getFortuneCoupon()"
          >{{ $t('campaignGetCoupon') }}</div>
          <div
            v-else-if="status === 'pending'"
            class="fortune-campaign-button disabled"
          >{{ $t('availableOn', { startDate: startTime }) }}</div>
          <div
            v-else-if="status === 'expired'"
            class="fortune-campaign-button disabled"
          >{{ $t('fortuneBagExpired') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import config from '@/config'
import { getLangName } from '@/utils/lang'
import { isWechatMiniProgram, getCampaignAPIPrefix } from '@/utils/utils'
import { loginSSO } from '@/helpers/http'
import axios from 'axios'
import { asyncGetCampaignDetail } from '@/helpers/http'
import { setupDefaultQueryString } from '../utils/utils'

// import env from '@env'

export default {
  name: 'FortuneCampaign',
  data: function () {
    return {
      isShowGetCouponButton: false,
      isActive: true,
      futureActivationTime: null,
      campaignId: null,
      responseCode: null,
      responseDetail: null,
      startDate: null,
      endDate: null,
      isDataCollected: false
    }
  },
  computed: {
    // campaignStartDate () {
    //   let result = ''
    //   if (this.campaignId && this.campaginData && this.campaginData.start_date) {
    //     result = this.getDateString(this.campaginData.start_date, 'D MMM YYYY')
    //   }

    //   return result
    // },
    // getFortuneButtonText () {
    //   /*
    //   if (String(this.responseCode) === '409' && !this.responseDetail) {
    //     // not yet started

    //     const campaignData = this.$store.getters['campaigns/campaignDetail'](this.campaignId)

    //     console.log('@68', campaignData)
    //     if (campaignData) {
    //       const campaignStartDate = this.getDateString(campaignData.start_date, 'D MMM YYYY')
    //       return this.$t('campaignAvailableOn') + ' ' + campaignStartDate
    //     }
    //   }
    //   */

    //   // const campaignData = this.$store.getters['campaigns/campaignDetail'](this.campaignId)
    //   // console.log('@85 ----123', campaignData)
    //   let FortuneButtonText = this.$t('campaignGetCoupon')
    //   // const now = this.testDate
    //   const now = new Date()
    //   console.log('@88---------123', this.campaignData)
    //   if (this.startDate && now <= new Date(this.startDate)) {
    //     // FortuneButtonText = this.$t('Available on') + this.getDateString(this.startDate, 'D MMM YYYY hh:mm')
    //     FortuneButtonText = this.$t('availableOn', { startDate: this.getDateString(this.startDate, 'D MMM YYYY hh:mm') })
    //   }
    //   if (this.endDate && now > new Date(this.endDate)) {
    //     FortuneButtonText = this.$t('fortuneBagExpired')
    //   }
    //   return FortuneButtonText
    // },
    status () {
      let status = 'active'
      const now = new Date()
      if (this.startDate && now <= new Date(this.startDate)) {
        status = 'pending'
      }
      if (this.endDate && now > new Date(this.endDate)) {
        status = 'expired'
      }
      console.log('@118', this.startDate, this.endDate)
      return status
    }
  },
  async mounted () {
    console.log('38----- mounted', this.$route.query)
    // walty 2021-12-03, nancy said no need check
    this.isShowGetCouponButton = true

    const {
      campaign_id: campaign
    } = this.$route.query

    this.campaignId = campaign
    await asyncGetCampaignDetail(campaign)
    this.isDataCollected = true
    const {
      start_date: startDate,
      end_date: endDate
    } = this.$store.getters['campaigns/campaignDetail'](this.campaignId)
    this.startDate = startDate
    this.endDate = endDate
    if (startDate) {
      this.startTime = this.getDateString(new Date(startDate), 'D MMM YYYY HH:mm')
    }
    console.log('@142 ---123', this.startDate, this.endDate)

    this.checkFortuneBagisValid()
    // const query = this.$route.query
    // if (query.source === 'email') {
    //  this.isShowGetCouponButton = true
    // }
  },
  beforeUpdate () {
  },

  methods: {
    async updateCampaignDetailIfNeeded () {
      const { recordId } = this.$route.query
      console.log('update record detail if needed', recordId, this.recordDetailCollected)
      if (!recordId || this.recordDetailCollected) { return }
      try {
        await this.$store.dispatch('campaignparticipationrecords/updateRecord', recordId)
      } catch (error) {
        console.log('fail to get record', error)
      }
      this.recordDetailCollected = true
    },
    async checkFortuneBagisValid () {
      const ssoUid = this.$store.getters['auth/ssoUid']

      if (!ssoUid) {
        return
      }

      const {
        campaign_id: campaign,
        partner_id: merchantId,
        entitle_id_type: entitle_id_type,
        entitle_id: entitle_id,
        amount: amount,
        sign: sign,
        ts: ts
      } = this.$route.query

      const postData = {
        campaign,
        brand: merchantId,
        entitle_id_type: entitle_id_type,
        entitle_id: entitle_id,
        amount: amount,
        sign: sign,
        ts: ts,
        check_link: true
      }
      try {
        const response = await axios.post(getCampaignAPIPrefix() + `/customers/${ssoUid}/campaign_participation_records?check_link=true`, postData)
        console.log('@695--- detail', response)
      } catch (error) {
        const response = error.response

        console.log('@101, fortune bag validation failed', response)
        let responseCode = response.status
        let responseDetail = response.data.display_error_content
        if (responseCode === 409 && response.data && response.data.code) {
          responseCode = response.data.code
          responseDetail = response.data.display_error_content
        }

        this.responseCode = responseCode
        this.responseDetail = responseDetail
        this.response = error.response

        this.isActive = false
      }
    },
    getFortuneCoupon () {
      const lang = getLangName(this.$i18n.locale)
      console.log('query----', window.location.search)
      let query = window.location.search
      if (query) { query += '&' }

      query += `lang=${lang}`

      let redeemUrl = `${process.env.VUE_APP_WALLET_URL}/redeem/${query}`
      const isLogin = localStorage.getItem('refreshToken')

      if (isWechatMiniProgram() && !isLogin) {
        loginSSO(redeemUrl)
        return
      }

      redeemUrl = setupDefaultQueryString(this.$route, redeemUrl)

      window.location.href = redeemUrl
    }
  }

}
</script>
<style scoped lang='scss'>

.fortune-campaign-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fortune-campaign-button{
  padding-left: 25px;
  padding-right: 25px;
}

.fortune-campaign-image{
  background-image: -webkit-image-set(
    url("/img/fortune-campaign-logo.1x.png") 1x,
    url("/img/fortune-campaign-logo.2x.png") 2x,
    url("/img/fortune-campaign-logo.3x.png") 3x);
  background-image: image-set(
    url("/img/fortune-campaign-logo.1x.png") 1x,
    url("/img/fortune-campaign-logo.2x.png") 2x,
    url("/img/fortune-campaign-logo.3x.png") 3x);
}

.is-vue-desktop {
    .fortune-campaign-content {
        margin-top: 30px;
    }
    .fortune-campaign-image-color {
        width: 100%;
        height: 254px;
        background: #FFF5EC;

    }

    .fortune-campaign-image {
        width: 653px;
        height: 513px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -286px;
    }
    .fortune-campaign-title {
        font-weight: bold;
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #05218A;
        text-align: center;
    }

    .fortune-campaign-container {
        height: 100%;
        background: #fff;
    }

    .fortune-campaign-buttons {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        margin-bottom: 54px;
    }

    .fortune-campaign-button {
      cursor: pointer;
        height: 48px;
        text-align: center;
        line-height: 48px;
        background: #E2002C;
        border-radius: 41px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;

    }

    .fortune-campaign-tip {
        font-size: 15px;
        line-height: 23px;
        color: #000000;
    }

    .fortune-campaign-tip:not(:first-child) {
        margin-top: 27px;
    }

    .fortune-campaign-tips {
        margin-top: 36px;
        margin-left: 138px;
        margin-right: 138px;
    }
}

.is-vue-mobile {
    .fortune-campaign-title {
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        color: #05218A;
        text-align: center;
    }

    .fortune-campaign-image-color {
        width: 100%;
        height: 202px;
        background: #FFF5EC;
    }

    .fortune-campaign-image {
        height: 266px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -212px;
        width: 100%;
        background-position: center;
    }

    .fortune-campaign-content {
        margin-top: 18px;
    }

    .fortune-campaign-tip {
        font-size: 14px;
        line-height: 22px;
        color: #2D2D2D;

        strong {
            color: #E2002C;
        }
    }

    .fortune-campaign-tip:not(:first-child) {
        margin-top: 29px;
    }

    .fortune-campaign-tips {
        margin-top: 20px;
        padding-left: 12px;
        padding-right: 15px;
    }

    .fortune-campaign-buttons {
        display: flex;
        justify-content: center;
        margin-top: 43px;
        margin-bottom: 35px;
    }

    .fortune-campaign-button {
        height: 48px;
        background: #E2002C;
        border-radius: 41px;
        font-weight: bold;
        font-size: 20px;
        line-height: 48px;
        color: #FFFFFF;
        text-align: center;
    }
}

.fortune-campaign-button.disabled {
  width: unset;
  padding-left: 43px;
  padding-right: 43px;
  background: #E5A1AE;
  cursor: default;
}
</style>
