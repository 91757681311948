<template>
  <div class="tour-card-container">
    <div class="tour-card-number">{{ tourCardNumber }}</div>
    <div class="tour-card-image" :style="{backgroundImage: `url(${tourCardImage})`}" />
    <div class="tour-card-title">{{ tourTitle }}</div>
    <div class="tour-card-content" v-html="tourDescirption" />
  </div>
</template>
<script>
export default {
  name: 'TourCard',
  props: ['tourinfo', 'number'],
  computed: {
    tourCardNumber () {
      const number = this.number + 1
      if (number < 10) {
        return '0' + number
      }
      return number
    },
    tourCardImage () {
      let tourCardImage = ''
      if (this.tourinfo) {
        tourCardImage = this.tourinfo.tour_card.cover_photo_url
      }
      return tourCardImage
    },
    tourDescirption () {
      let tourDescirption = ''
      if (this.tourinfo) {
        tourDescirption = this.tourinfo.tour_card.description
      }
      return tourDescirption
    },
    tourTitle () {
      let tourTitle = ''
      if (this.tourinfo) {
        tourTitle = this.tourinfo.tour_card.title
      }
      return tourTitle
    }
  },
  mounted () {
    // console.log('this tourInfo', this.tourinfo)
  }
}
</script>
<style lang="scss" scoped>
.tour-card-container {
    position: relative;
    background: #E6E9F3;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 34px;
    padding-bottom: 19px;
    border-radius: 10px;
    display: inline-block;
    width: 280px;
}

.tour-card-number {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #05218A;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    line-height: 50px;
    top: 10px;
    left: 10px;
}

.tour-card-title {
    font-weight: 700;
    text-align: left;
    font-size: 15px;
    line-height: 24px;
}

.tour-card-content {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    line-height: 22.75px;
}

.tour-card-image {
    padding-top: calc(272/362 * 100%);
    height: 0px;
    background-position: center;
    background-size: cover;
    margin-bottom: 20px;
}

.is-vue-mobile {
    .tour-card-container {
        padding: 20px;
        width: 100%;
    }
    .tour-card-number {
        font-size: 18px;
        font-weight: 600;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        line-height: 44px;
    }
    .tour-card-title {
        font-weight: 700;
        text-align: left;
        font-size: 14px;
        line-height: 22.5px;
    }

    .tour-card-content {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
    }
}
</style>
