import jwt_decode from 'jwt-decode'

export const auth = {
  namespaced: true,
  state: {
    profile: { first_name: '', last_name: '' },
    isShowCouponAlertFromOtherChannels: false,
    isShowGoodieAlertFromOtherChannels: false,
    ssoUid: '',
    trackingSsoUid: '',
    trackingMembershipId: '',
    trackingCountryCode: ''
  },
  mutations: {
    loginUser (state, tokenInfo) {
      console.log('10--- access token: ', tokenInfo)
      const jwtInfo = jwt_decode(tokenInfo.accessToken)
      if (jwtInfo && jwtInfo.sub) {
        state.ssoUid = jwtInfo.sub
        state.trackingSsoUid = jwtInfo.sub
      }
    },
    showCouponAlertFromOtherChannels (state) {
      state.isShowCouponAlertFromOtherChannels = true
    },
    closeCouponAlertFromOtherChannels (state) {
      state.isShowCouponAlertFromOtherChannels = false
    },
    showGoodieAlertFromOtherChannels (state) {
      state.isShowGoodieAlertFromOtherChannels = true
    },
    closeGoodieAlertFromOtherChannels (state) {
      state.isShowGoodieAlertFromOtherChannels = false
    },
    setProfile (state, profile) {
      console.log('rpofile store: ', profile)
      state.profile = profile
      state.trackingMembershipId = profile['membership_id']
      state.trackingCountryCode = profile['mobile_phone_number_country_code']
      console.log('@ srtate. profile', state.profile)
    },
    clearUser (state) {
      state.profile = {}
      state.ssoUid = ''
      state.isShowCouponAlertFromOtherChannels = false
      state.isShowGoodieAlertFromOtherChannels = false
      state.trackingCountryCode = null
    }
  },
  getters: {
    isShowCouponAlertFromOtherChannels: state => {
      return state.isShowCouponAlertFromOtherChannels
    },
    isShowGoodieAlertFromOtherChannels: state => {
      return state.isShowGoodieAlertFromOtherChannels
    },
    userProfile: state => {
      // console.log('statee profile', state.profile)
      return state.profile
    },
    ssoUid: state => {
      return state.ssoUid
    },
    trackingSsoUid: state => {
      return state.trackingSsoUid
    },
    trackingMembershipId: state => {
      return state.trackingMembershipId
    },
    trackingCountryCode: state => {
      return state.trackingCountryCode
    },
    isTestUser: state => {
      let isTestUser = false
      const profile = state.profile
      if (profile && profile.is_preview_tester) {
        isTestUser = true
      }
      return isTestUser
    }
  }

}

export default auth
