<template>
  <div class="my-account">
    <div class="page-title">{{ $t('headerMyAccountLow') }}</div>
    <el-card class="my-account-card">
      <div class="container account-title">{{ $t('myAccountAccountInformation') }}</div>
      <div class="title-tip" v-html="$t('myAccountRequired')" />
      <form autoComplete="off" @submit.prevent="">
        <div class="columns item-container">
          <div v-if="signUpMethod=='EMAIL_ADDRESS'" class="column account-item">
            <div class="label is-flex disabled-item">{{ $t('myAccountEmail') }}</div>
            <input v-model="email" class="input no-border disable-input" disabled>
          </div>
          <div v-else class="column account-item">
            <div class="label is-flex disabled-item">{{ $t('profileMobileNo.') }}</div>
            <input :value="'+' + oldCountryCode + '  ' + oldMobilePhone" class="input no-border disable-input" disabled>
          </div>
          <div class="column account-item">
            <div class="label is-flex disabled-item">{{ $t('homeMembershipID') }}</div>
            <input v-model="membershipId" class="input no-border disable-input" disabled>
          </div>
          <div class="column account-item m-half">
            <div class="label is-flex">{{ $t('myAccountFirstName') }}<span class="red">*</span></div>
            <input v-model="fname" class="input under-border" maxlength="128" :placeholder="$t('myAccountIam')" @input="setHasEditUserInfo">
          </div>
          <div class="column account-item m-half">
            <div class="label is-flex">{{ $t('myAccountLastName') }}<span class="red">*</span></div>
            <input v-model="lname" class="input under-border" maxlength="128" :placeholder="$t('myAccountIam')" @input="setHasEditUserInfo">

          </div>
          <div id="nickname-field" class="column account-item" :class="{ 'password-error': nickNameError}">
            <div class="label is-flex">{{ $t('myAccountNickname') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
            <input v-model="nickname" class="input under-border" :placeholder="$t('myAccountIam')" maxlength="128" @input="setHasEditUserInfo">
            <div v-if="nickNameError" class="item-tip">{{ nickNameError }}</div>
          </div>
          <div class="column account-item" :class="{ 'password-error': salutationError}">
            <div class="label is-flex">{{ $t('myAccountSalutationLabel') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
            <el-select v-model="salutation" :placeholder="$t('myAccountSalutation')" class="account-select" @change="setHasEditUserInfo">
              <el-option
                v-for="item in salutationOptions"
                :key="item.value"
                :label="getLabel(item)"
                :value="item.value"
              />
            </el-select>
            <div v-if="salutationError" class="item-tip">{{ salutationError }}</div>
          </div>
          <!--<div class="column account-item" :class="{ 'password-error': residenceError}">
            <div class="label is-flex">{{ $t('myAccountResidence') }}</div>
            <el-select
              ref="select"
              v-model="residence"
              filterable
              :placeholder="$t('myAccountIamFrom')"
              class="account-select"
              @hook:mounted="cancelReadOnly"
              @visible-change="cancelReadOnly"
              @change="setHasEditUserInfo"
            >
              <el-option
                v-for="item in residenceOptions"
                :key="item.value"
                :label="getLabel(item)"
                :value="item.id"
              />
            </el-select>
            <div v-if="residenceError" class="item-tip">{{ residenceError }}</div>
          </div>-->
          <div class="column account-item">
            <div class="label is-flex">{{ $t('myAccountBirthMonthYear') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
            <div class="item-tip">{{ $t('myAccountEditBirthprompt') }}</div>
            <el-date-picker
              v-model="birth"
              class="account-datePicker"
              type="month"
              value-format="1900-MM-dd"
              :format="'MMMM'"
              :placeholder="$t('myAccountSelectBirthdate')"
              align="center"
              :readonly="hasBirth"
              :editable="false"
              prefix-icon="none"
              :picker-options="pickerOptions"
              @change="setHasEditUserInfo"
            />
          </div>
          <div v-if="signUpMethod=='EMAIL_ADDRESS'" class="column account-item" :class="{ 'password-error': verificationError || phoneNumberError}">
            <div class="label is-flex">{{ $t('myAccountMobileNo') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
            <div class="is-flex mobile phone">
              <el-select v-model="countryCode" style="width:26%" :placeholder="$t('generalCountryCode')" class="account-select" @change="setHasEditUserInfo">
                <el-option
                  v-for="item in countryCodeOptions"
                  :key="item.value"
                  :label="getLabel(item)"
                  :value="item.value"
                />
              </el-select>
              <div style="margin-left:13px;width:74%" class="control has-icons-right">
                <input v-model="mobilePhone" type="number" :placeholder="$t('profileMobileNo.')" maxlength="128" pattern="[0-9]+" class="input under-border mobile-no" @input="setHasEditUserInfo">

                <span v-if="!sendVerificationCodeStatus && mobilePhone!==oldMobilePhone" class="icon is-small is-right send-code" @click="sendPhoneCode()">
                  {{ $t('profileSendCode') }}
                </span>

                <span v-if="parseInt(sendVerificationCodeStatus)" class="icon is-small is-right send-code wait-resend">
                  {{ $t('profileResendInSeconds', [sendVerificationCodeStatus]) }}
                </span>

                <span v-if="sendVerificationCodeStatus==='ready_resend'" class="icon is-small is-right send-code ready-resend" @click="sendPhoneCode()">
                  {{ $t('profileResend') }}
                </span>

                <span v-if="sendVerificationCodeStatus==='verified'" class="icon is-small is-right send-code-img">
                  <img src="/img/phone_verified.png">
                </span>

              </div>

            </div>
            <div class="item-prompt">{{ $t('profileAddSecondarycontact') }}</div>

            <div v-if="phoneNumberError" class="item-tip">{{ phoneNumberError }}</div>

            <input v-model="verificationCode" type="number" maxlength="128" :placeholder="$t('profileVerificationCode')" pattern="[0-9]+" class="input under-border verification-code" @input="setHasEditUserInfo">
            <div class="item-prompt">{{ verificationTip }}</div>
            <div v-if="verificationError" class="item-tip ">{{ verificationError }}</div>

          </div>
          <div v-else id="emaill-address-field" class="column account-item emaill-address-field" :class="{ 'password-error': emailAddressError || verificationError}">
            <div class="label is-flex">{{ $t('profileEmailAddress') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
            <div class="item-prompt">{{ $t('profileAddSecondarycontact') }}</div>
            <div class="is-flex mobile second-email">
              <div style="width:100%" class="control has-icons-right">
                <input v-model="email" name="email_address" maxlength="128" autoComplete="off" :placeholder="$t('profileEmailPlaceHolder')" class="input under-border" @input="setHasEditUserInfo">

                <!--<span v-if="!sendVerificationCodeStatus && email!==oldEmail" class="icon is-small is-right send-code" @click="sendEmailCode()">
                  {{ $t('profileSendCode') }}
                </span>

                <span v-if="parseInt(sendVerificationCodeStatus)" class="icon is-small is-right send-code wait-resend">
                  {{ $t('profileResendInSeconds', [sendVerificationCodeStatus]) }}
                </span>

                <span v-if="sendVerificationCodeStatus==='ready_resend'" class="icon is-small is-right send-code ready-resend" @click="sendEmailCode()">
                  {{ $t('profileResend') }}
                </span>

                <span v-if="sendVerificationCodeStatus==='verified'" class="icon is-small is-right send-code-img">
                  <img src="/img/phone_verified.png">
                </span>-->

              </div>
            </div>
            <div v-if="emailAddressError" class="item-tip">{{ emailAddressError }}</div>
            <div v-else-if="emailAddressVerified && oldEmail === email && oldEmail" class="item-bottom-prompt"><img src="/img/phone_verified.png"><span>{{ $t('profileEmailVerified') }}</span></div>
            <div v-else-if="!emailAddressVerified && oldEmail === email && oldEmail" class="item-bottom-prompt">
              <img src="/img/criticalAlert.1x.png">
              <span>{{ $t('profileEmailNeedVerify') }}</span>
              <span v-if="!sendVerificationEmailStatus" class="btn" @click="sendVerificationEmail()">{{ $t('profileEmailVerifySendCode') }}</span>
              <span v-if="parseInt(sendVerificationEmailStatus)" class="btn btn-disabled">{{ $t('profileEmailVerifyResendCode') }} ({{ sendVerificationEmailStatus }} s)</span>
              <span v-if="sendVerificationEmailStatus==='ready_resend'" class="btn" @click="sendVerificationEmail()">{{ $t('profileEmailVerifyResendCode') }}</span>
            </div>

            <!--<input v-model="verificationCode" type="number" maxlength="128" :placeholder="$t('profileVerificationCode')" pattern="[0-9]+" class="input under-border verification-code" @input="setHasEditUserInfo">
            <div v-if="verificationTip" class="item-tip ">{{ verificationTip }}</div>
            <div v-if="verificationError" class="item-tip">{{ verificationError }}</div>-->
          </div>
          <div class="columns item-container">
            <div class="column is-full account-item">
              <div class="directMarketing">
                <el-checkbox v-model="directMarketing" :label="$t('myAccountDirectMarketing')" @change="setHasEditUserInfo" />
              </div>
              <div class="directMarketing-tip">{{ $t('myAccountDirectMarketinghints') }}</div>
            </div>
          </div>
        </div>
        <div>
          <button v-if="hasEditUserInfo && fname && lname" class="update" :class="{'primary-button': hasEditUserInfo}" @click="updateUserInfo">{{ $t('myAccountUpdate') }}</button>
          <button v-else class="update">{{ $t('myAccountUpdate') }}</button>
        </div>
      </form>
    </el-card>
    <el-card class="my-account-card">
      <div class="container account-title">{{ $t('myAccountAccountSettings') }}</div>
      <div class="title-tip" v-html="$t('myAccountRequired')" />
      <div class="columns item-container">
        <div class="column account-item">
          <div class="label is-flex">{{ $t('myAccountLanguagePreference') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
          <el-select v-model="languagePreference" :placeholder="$t('myAccountEditLanguageRegion')" class="account-select" @change="setHasEditUserSettings">
            <el-option
              v-for="item in languagePreferenceOptions"
              :key="item.value"
              :label="getLabel(item)"
              :value="item.value"
              :class="item.class"
            />
          </el-select>
        </div>
      </div>
      <div class="columns item-container">
        <div class="column is-full account-item">
          <div class="label is-flex">{{ $t('myAccountInterestPreference') }}<span class="optional">{{ $t('myAccountOptional') }}</span></div>
          <el-checkbox-group v-model="interestPreference" class="account-checkbox-group" @change="setHasEditUserSettings">
            <el-checkbox-button
              v-for="item in interestPreferences"
              :key="item.id"
              :label="item.id"
            >
              {{ item.name }}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <div>
        <button class="update" :class="{'primary-button': hasEditUserSettings}" @click="saveAccountSettings">{{ $t('myAccountUpdate') }}</button>
      </div>
    </el-card>
    <el-card v-if="!isMiniProgram()" class="my-account-card">
      <div class="container account-title">{{ $t('myAccountChangePassword') }}</div>
      <div class="title-tip" v-html="$t('myAccountRequired')" />
      <form autoComplete="off" @submit.prevent="">
        <div class="columns item-container">
          <div id="current-password-field" class="column account-item" :class="{ 'password-error': currentPasswordError}">
            <div class="label is-flex">{{ $t('myAccountCurrentPassword') }}<span class="red">*</span></div>
            <div class="control has-icons-right">
              <input v-model="currentPassword" name="current_password" maxlength="128" autoComplete="off" :placeholder="$t('myAccountCurrentPassword')" :type="currentPasswordType" class="input under-border" @change="hasEditPassword = true">
              <span v-if="currentPasswordType==='password'" class="icon is-small is-right password-visible" @click="currentPasswordType='text'">
                <img
                  class="password-visible-icon"
                  srcset="/img/invisible.1x.png, /img/invisible.2x.png 2x, /img/invisible.3x.png 3x"
                  src="/img/invisible.2x.png"
                >
              </span>
              <span v-else class="icon is-small is-right password-visible" @click="currentPasswordType='password'">
                <img
                  class="password-invisible-icon"
                  srcset="/img/visible.1x.png, /img/visible.2x.png 2x, /img/visible.3x.png 3x"
                  src="/img/visible.2x.png"
                >
              </span>
            </div>
            <div v-if="currentPasswordError" class="item-tip">{{ currentPasswordError }}</div>
          </div>
        </div>
        <div class="columns item-container">
          <div id="new-password-field" class="column account-item" :class="{ 'password-error': hasPasswordError}">
            <div class="label is-flex">{{ $t('myAccountNewPassword') }}<span class="red">*</span></div>
            <div class="control has-icons-right">
              <input v-model="newPassword" name="new_password" maxlength="128" autoComplete="off" :placeholder="$t('myAccountNewPassword')" :type="newPasswordType" class="input under-border" @change="hasEditPassword = true">
              <span v-if="newPasswordType==='password'" class="icon is-small is-right password-visible" @click="newPasswordType='text'">
                <img
                  class="password-visible-icon"
                  srcset="/img/invisible.1x.png, /img/invisible.2x.png 2x, /img/invisible.3x.png 3x"
                  src="/img/invisible.2x.png"
                >
              </span>
              <span v-else class="icon is-small is-right password-visible" @click="newPasswordType='password'">
                <img
                  class="password-invisible-icon"
                  srcset="/img/visible.1x.png, /img/visible.2x.png 2x, /img/visible.3x.png 3x"
                  src="/img/visible.2x.png"
                >
              </span>
            </div>
            <div class="item-tip">{{ newPasswordError?newPasswordError:$t('myAccountPasswordErrormessage') }}</div>
          </div>
          <div class="column account-item" :class="{ 'password-error': hasConfirmPasswordError}">
            <div class="label is-flex">{{ $t('generalConfirmNewPassword') }}<span class="red">*</span></div>
            <div class="control has-icons-right">
              <input v-model="confirmNewPassword" name="confirm_new_password" maxlength="128" autoComplete="off" :placeholder="$t('myAccountNewPassword')" :type="confirmNewPasswordType" class="input under-border" @change="hasEditPassword = true">
              <span v-if="confirmNewPasswordType==='password'" class="icon is-small is-right password-visible" @click="confirmNewPasswordType='text'">
                <img
                  class="password-visible-icon"
                  srcset="/img/invisible.1x.png, /img/invisible.2x.png 2x, /img/invisible.3x.png 3x"
                  src="/img/invisible.2x.png"
                >
              </span>
              <span v-else class="icon is-small is-right password-visible" @click="confirmNewPasswordType='password'">
                <img
                  class="password-invisible-icon"
                  srcset="/img/visible.1x.png, /img/visible.2x.png 2x, /img/visible.3x.png 3x"
                  src="/img/visible.2x.png"
                >
              </span>
            </div>
            <div class="item-tip confirm-password-tip">{{ $t('generalTwoPasswordserrorMessage') }}</div>
          </div>
        </div>
        <div>
          <button data-aid="account_change_password_update" class="update" :class="{'primary-button': newPassword}" @click="saveAccountPassword">{{ $t('myAccountUpdate') }}</button>
        </div>
      </form>
    </el-card>
    <el-card class="my-account-card login-methods">
      <div class="container account-title">{{ $t('myAccountLoginMethods') }}</div>
      <div class="columns item-container">
        <div v-if="canLoginEmail" class="column account-item row" :class="{'is-full': $store.getters['app/isMobile']}">
          <img
            width="17px"
            srcset="/img/account_email.1x.png, /img/account_email.2x.png 2x, /img/account_email.3x.png 3x"
            src="/img/account_email.2x.png"
          >
          <div>{{ $t('profileEmailPlaceHolder') }}</div>
        </div>
        <div v-if="canLoginMobile" class="column account-item row mobile" :class="{'is-full': $store.getters['app/isMobile']}">
          <img
            width="15px"
            srcset="/img/mobile.1x.png, /img/mobile.2x.png 2x, /img/mobile.3x.png 3x"
            src="/img/mobile.2x.png"
          >
          <div>{{ $t('loginMobileNo') }}</div>
        </div>
        <hr v-if="$store.getters['app/isMobile'] && canLoginMobile && canLoginWechat">
        <div v-if="canLoginWechat" class="column account-item row wechat" :class="{'is-full': $store.getters['app/isMobile']}">
          <img
            width="22px"
            srcset="/img/wechat_my_account.1x.png, /img/wechat_my_account.2x.png 2x, /img/wechat_my_account.3x.png 3x"
            src="/img/wechat_my_account.2x.png"
          >
          <div>{{ $t('myAccountWeChatAccount') }} ({{ '+' + oldCountryCode + '  ' + oldMobilePhone }})</div>
        </div>
      </div>
    </el-card>
    <el-card class="my-account-card delete-account last-card">
      <div class="container account-title">{{ $t('myAccountDeleteAccount') }}</div>
      <div class="columns item-container">
        <div class="column is-full account-item">
          {{ $t('myAccountDeleteAccountPrompt') }}
        </div>
      </div>
      <div>
        <button data-aid="account_delete" class="delete-bt secondary-button" @click="isShowDeleteConfirm = true">{{ $t('myAccountDelete') }}</button>
      </div>
    </el-card>
    <el-dialog
      :visible.sync="isShowUnsaveConfirm"
      width="600px"
      top="calc(50vh - 114px)"
      class="unsave-confirm"
    >
      <span slot="title">
        <div class="account-dialog-title desktop">
          {{ $t('myAccountUnsavedPromptTile') }}
        </div>
        <div class="account-dialog-title mobile">
          {{ $t('myAccountUnsavedPromptTile') }}
        </div>
      </span>
      <div class="">
        <div class="account-dialog-message">{{ $t('myAccountUnsavedPromptDescription') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="account-dialog-cancel secondary-button desktop" @click="dischardChange">{{ $t('myAccountUnsavedPromptDiscardchanges') }}</el-button>
        <el-button type="primary" class="account-dialog-save primary-button desktop" @click="saveChanges">{{ $t('myAccountUnsavedPromptSave') }}</el-button>
        <el-button class="account-dialog-cancel secondary-button mobile" @click="dischardChange">{{ $t('myAccountUnsavedPromptDiscardchanges') }}</el-button>
        <el-button type="primary" class="account-dialog-save primary-button mobile" @click="saveChanges">{{ $t('myAccountUnsavedPromptSave') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowBirthConfirm"
      width="600px"
      top="calc(50vh - 114px)"
    >
      <span slot="title">
        <div class="account-dialog-title">
          {{ $t('myAccountBirthConfirmPromptTitle') }}
        </div>
      </span>
      <div v-if="isBirthDayChanged" class="">
        <div class="account-dialog-message">{{ $t('myAccountBirthConfirmPromptDescription') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="account-dialog-cancel secondary-button" @click="isShowBirthConfirm = false">{{ $t('myAccountBirthConfirmPromptCancel') }}</el-button>
        <el-button type="primary" class="account-dialog-save primary-button" @click="saveAccountInfo">{{ $t('myAccountBirthConfirmPromptConfirm') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowDeleteConfirm"
      width="600px"
      top="calc(50vh - 114px)"
    >
      <span slot="title">
        <div class="account-dialog-title">
          {{ $t('myAccountDeleteAccountconfirmPromptTitle') }}
        </div>
      </span>
      <div class="">
        <div class="account-dialog-message">{{ $t('myAccountDeleteAccountconfirmPromptDescription') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="account-dialog-cancel secondary-button" @click="isShowDeleteConfirm = false">{{ $t('myAccountDeleteAccountconfirmPromptCancel') }}</el-button>
        <el-button type="primary" class="account-dialog-save primary-button" @click="confirmDeleteAccount">{{ $t('myAccountDeleteAccountconfirmPromptConfirm') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowVerifyConfirm"
      width="600px"
      top="calc(50vh - 114px)"
      :before-close="beforeCloseVerifyConfirm"
    >
      <span slot="title">
        <div class="account-dialog-title">{{ $t('myAccountDeleteMolAccountconfirm2PromptTitle') }}</div>
      </span>
      <div class="">
        <div v-if="signUpMethod=='EMAIL_ADDRESS'" class="account-dialog-message">{{ $t('myAccountDeleteAccountconfirm2PromptDescription') }}</div>
        <div v-else class="account-dialog-message">{{ $t(isSendDeleteMessageBySMS ? 'myAccountDeleteMolAccountBySMSconfirm2PromptDescription' : 'myAccountDeleteMolAccountByWhatsAppconfirm2PromptDescription') }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="account-dialog-save primary-button" @click="closeVerifyConfirm()">{{ $t('myAccountDeleteAccountconfirm2PromptOK') }}</el-button>
      </span>
    </el-dialog>
    <!--
    <div>my account</div>
    <div>first name: {{ profile.first_name }}</div>
    <div>last name: {{ profile.last_name }}</div>
    <div>birth date: {{ profile.birth_date }}</div>
    -->
    <recaptcha-verify-fail-dialog
      :is-show-recaptcha-fail="isShowRecaptchaFail"
      :recaptcha-fail-title="recaptchaFailTitle"
      :recaptcha-fail-content="recaptchaFailContent"
      @setIsShowRecaptchaFail="isShowRecaptchaFail = $event"
    />

    <div id="robot" />
  </div>
</template>
<script>
import axios from 'axios'
// import config from '@/config'
// import env from '@env'
import RecaptchaVerifyFailDialog from '@/components/RecaptchaVerifyFailDialog'
import utilsMixin from '@/mixins/utilsMixin'
import { isWechatMiniProgram, pushRouterWithQuery } from '@/utils/utils'
import { sendManualAnalytics, sendAdobePageViewAnalytics } from '@/utils/tracking'

export default {
  name: 'MyAccount',
  components: {
    RecaptchaVerifyFailDialog
  },
  mixins: [utilsMixin],
  data: function () {
    return {
      fname: '',
      oldFirstName: '',
      lname: '',
      oldLastName: '',
      email: '',
      nickname: '',
      oldNickName: '',

      isSaveUser: false,
      hasEditUserInfo: false,
      hasBirth: false,
      hasEditPassword: false,
      hasEditUserSettings: false,
      phoneNumberError: null,
      emailAddressError: null,
      verificationError: null,
      sendVerificationCodeStatus: null,
      sendVerificationEmailStatus: null,
      interval: null,
      residenceError: null,
      salutationError: null,
      nickNameError: null,
      currentPasswordError: null,
      pickerOptions: {
        // disabledDate (time) {
        //  const startDate = new Date()
        //  startDate.setFullYear(startDate.getFullYear() - 100)

        // console.log('@403', startDate)

        // return time.getTime() < startDate.getTime() || time.getTime() > Date.now()
        // }
      },
      residenceOptions: [
        { label: 'Argentina', value: 'Argentina', label_sc: '阿根廷', label_tc: '阿根廷', id: '1' },
        { label: 'Australia', value: 'Australia', label_sc: '澳大利亚', label_tc: '澳洲', id: '2' },
        { label: 'Austria', value: 'Austria', label_sc: '奥地利', label_tc: '奧地利', id: '3' },
        { label: 'Bahrain', value: 'Bahrain', label_sc: '巴林', label_tc: '巴林', id: '4' },
        { label: 'Belgium', value: 'Belgium', label_sc: '比利时', label_tc: '比利時', id: '5' },
        { label: 'Brazil', value: 'Brazil', label_sc: '巴西', label_tc: '巴西', id: '6' },
        { label: 'Canada', value: 'Canada', label_sc: '加拿大', label_tc: '加拿大', id: '7' },
        { label: 'Denmark', value: 'Denmark', label_sc: '丹麦', label_tc: '丹麥', id: '8' },
        { label: 'Egypt', value: 'Egypt', label_sc: '埃及', label_tc: '埃及', id: '9' },
        { label: 'Fiji', value: 'Fiji', label_sc: '斐济', label_tc: '斐濟', id: '10' },
        { label: 'Finland', value: 'Finland', label_sc: '芬兰', label_tc: '芬蘭', id: '11' },
        { label: 'France', value: 'France', label_sc: '法国', label_tc: '法國', id: '12' },
        { label: 'Germany', value: 'Germany', label_sc: '德国', label_tc: '德國', id: '13' },
        { label: 'Honduras', value: 'Honduras', label_sc: '洪都拉斯', label_tc: '洪都拉斯', id: '14' },
        { label: 'Hong Kong SAR', value: 'Hong Kong SAR', label_sc: '香港特別行政区', label_tc: '香港特別行政區', id: '15' },
        { label: 'India', value: 'India', label_sc: '印度', label_tc: '印度', id: '16' },
        { label: 'Indonesia', value: 'Indonesia', label_sc: '印度尼西亚', label_tc: '印尼', id: '17' },
        { label: 'Israel', value: 'Israel', label_sc: '以色列', label_tc: '以色列', id: '18' },
        { label: 'Italy', value: 'Italy', label_sc: '意大利', label_tc: '義大利', id: '19' },
        { label: 'Japan', value: 'Japan', label_sc: '日本', label_tc: '日本', id: '20' },
        { label: 'Jordan', value: 'Jordan', label_sc: '约旦', label_tc: '約旦', id: '21' },
        { label: 'Kuwait', value: 'Kuwait', label_sc: '科威特', label_tc: '科威特', id: '22' },
        { label: 'Macao SAR', value: 'Macao SAR', label_sc: '澳门特別行政区', label_tc: '澳門特別行政區', id: '23' },
        { label: 'Mainland', value: 'Mainland', label_sc: '內地', label_tc: '內地', id: '24' },
        { label: 'Malaysia', value: 'Malaysia', label_sc: '马来西亚', label_tc: '馬來西亞', id: '25' },
        { label: 'Mauritius', value: 'Mauritius', label_sc: '毛里求斯', label_tc: '模里西斯', id: '26' },
        { label: 'Mexico', value: 'Mexico', label_sc: '墨西哥', label_tc: '墨西哥', id: '27' },
        { label: 'Netherlands', value: 'Netherlands', label_sc: '荷兰', label_tc: '荷蘭', id: '28' },
        { label: 'New Zealand', value: 'New Zealand', label_sc: '新西兰', label_tc: '紐西蘭', id: '29' },
        { label: 'Norway', value: 'Norway', label_sc: '挪威', label_tc: '挪威', id: '30' },
        { label: 'Pakistan', value: 'Pakistan', label_sc: '巴基斯坦', label_tc: '巴基斯坦', id: '31' },
        { label: 'Philippines', value: 'Philippines', label_sc: '菲律宾', label_tc: '菲律賓', id: '32' },
        { label: 'Portugal', value: 'Portugal', label_sc: '葡萄牙', label_tc: '葡萄牙', id: '33' },
        { label: 'Russia', value: 'Russia', label_sc: '俄罗斯', label_tc: '俄羅斯', id: '34' },
        { label: 'Saudi Arabia', value: 'Saudi Arabia', label_sc: '沙特阿拉伯', label_tc: '沙烏地阿拉伯', id: '35' },
        { label: 'Singapore', value: 'Singapore', label_sc: '新加坡', label_tc: '新加坡', id: '36' },
        { label: 'South Africa', value: 'South Africa', label_sc: '南非', label_tc: '南非', id: '37' },
        { label: 'South Korea', value: 'South Korea', label_sc: '韩国', label_tc: '韓國', id: '38' },
        { label: 'Spain', value: 'Spain', label_sc: '西班牙', label_tc: '西班牙', id: '39' },
        { label: 'Sri Lanka', value: 'Sri Lanka', label_sc: '斯里兰卡', label_tc: '斯里蘭卡', id: '40' },
        { label: 'Sweden', value: 'Sweden', label_sc: '瑞典', label_tc: '瑞典', id: '41' },
        { label: 'Switzerland', value: 'Switzerland', label_sc: '瑞士', label_tc: '瑞士', id: '42' },
        { label: 'Taiwan', value: 'Taiwan', label_sc: '台湾', label_tc: '台灣', id: '43' },
        { label: 'Thailand', value: 'Thailand', label_sc: '泰国', label_tc: '泰國', id: '44' },
        { label: 'Turkey', value: 'Turkey', label_sc: '土耳其', label_tc: '土耳其', id: '45' },
        { label: 'U.K.', value: 'U.K.', label_sc: '英国', label_tc: '英國', id: '46' },
        { label: 'U.S.A.', value: 'U.S.A.', label_sc: '美国', label_tc: '美國', id: '47' },
        { label: 'United Arab Emirates', value: 'United Arab Emirates', label_sc: '阿联酋', label_tc: '阿拉伯聯合大公國', id: '48' },
        { label: 'Venezuela', value: 'Venezuela', label_sc: '委内瑞拉', label_tc: '委內瑞拉', id: '49' },
        { label: 'Others - Africa', value: 'Others - Africa', label_sc: '其他地区 – 非洲', label_tc: '其他地區 – 非洲', id: '50' },
        { label: 'Others - Asian &amp; Indian Sub-Continent', value: 'Others - Asian &amp; Indian Sub-Continent', label_sc: '其他地区 – 亚洲及印度次大陆', label_tc: '其他地區 – 亞洲及印度次大陸', id: '51' },
        { label: 'Others - Central America', value: 'Others - Central America', label_sc: '其他地区 – 中美洲', label_tc: '其他地區 – 中美洲', id: '52' },
        { label: 'Others - Europe', value: 'Others - Europe', label_sc: '其他地区 – 欧洲', label_tc: '其他地區 – 歐洲', id: '53' },
        { label: 'Others - Middle East', value: 'Others - Middle East', label_sc: '其他地区 – 中东', label_tc: '其他地區 – 中東', id: '54' },
        { label: 'Others - South America', value: 'Others - South America', label_sc: '其他地区 – 南美洲', label_tc: '其他地區 – 南美洲', id: '55' },
        { label: 'Others – South East Asia', value: 'Others – South East Asia', label_sc: '其他地区 – 东南亚', label_tc: '其他地區 – 東南亞', id: '56' },
        { label: 'Others – South Pacific', value: 'Others – South Pacific', label_sc: '其他地区 – 南太平洋', label_tc: '其他地區 – 南太平洋', id: '57' }
      ],
      residence: '',
      oldResidence: '',
      salutation: '',
      oldSalutation: '',
      salutationOptions: [
        { label: 'Mr', value: 'MR', label_sc: '先生', label_tc: '先生' },
        { label: 'Ms', value: 'MS', label_sc: '女士', label_tc: '女士' },
        { label: 'Mrs', value: 'MRS', label_sc: '太太', label_tc: '太太' }
      ],
      countryCode: '',
      oldCountryCode: '',
      oldMobilePhone: '',
      oldEmail: '',
      emailAddressVerified: false,
      mobilePhone: '',
      countryCodeOptions: [
        { label: '+852', value: '852' },
        { label: '+853', value: '853' },
        { label: '+86', value: '86' }
      ],
      birth: '',
      oldBirth: '',
      languagePreference: 'zh-Hant',
      oldLanguagePreference: 'zh-Hant',
      interestPreference: [],
      oldInterestPreference: [],
      interestPreferenceOptions: [
        'Hotels',
        'Staycations',
        'Attractions'
      ],
      directMarketing: false,
      oldDirectMarketing: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isShowUnsaveConfirm: false,
      isShowBirthConfirm: false,
      isShowDeleteConfirm: false,
      isShowVerifyConfirm: false,
      isSendDeleteMessageBySMS: true,
      isBirthDayChanged: false,
      toUrl: null,
      currentPasswordType: 'password',
      newPasswordType: 'password',
      confirmNewPasswordType: 'password',
      verificationCode: '',
      hasPasswordError: false,
      hasConfirmPasswordError: false,
      newPasswordError: '',
      signUpMethod: '',
      membershipId: '',
      wechatLinked: false,
      isShowRecaptchaFail: false,
      recaptchaFailTitle: '',
      recaptchaFailContent: ''
    }
  },
  computed: {
    canLoginEmail () {
      return this.signUpMethod === 'EMAIL_ADDRESS'
    },
    canLoginMobile () {
      return this.signUpMethod === 'MOBILE_PHONE_NUMBER'
    },
    canLoginWechat () {
      return this.signUpMethod === 'WECHAT' || this.wechatLinked
    },
    verificationTip () {
      var result = ''
      if (this.signUpMethod === 'EMAIL_ADDRESS') {
        if (this.countryCode === '86') {
          result = this.$t('profileVerificationTip')
        } else {
          result = this.$t('profileAddSecondarycontactPhoneReminder')
        }
      }

      return result
    },

    languagePreferenceOptions () {
      // const enLang = { label: 'English', value: 'en', label_sc: '英语', label_tc: '英語', class: 'hide' }
      // const scLang = { label: 'Simplified Chinese', value: 'zh-Hans', label_sc: '简体中文', label_tc: '簡體中文', class: 'hide' }
      // const tcLang = { label: 'Tradtional Chinese', value: 'zh-Hant', label_sc: '繁体中文', label_tc: '繁體中文', class: 'hide' }

      // if (this.residence === '24') { // main land
      //  scLang.class = ''
      // } else if (this.residence === '15' || this.residence === '23' || this.residence === '43') { // hk, macau or taiwan
      //  enLang.class = ''
      //  tcLang.class = ''
      // } else if (this.residence === '36') { // singapore
      //  enLang.class = ''
      //  scLang.class = ''
      // } else if (this.residence === '') {
      //  enLang.class = ''
      //  scLang.class = ''
      //  tcLang.class = ''
      // } else {
      //  enLang.class = ''
      //  tcLang.class = ''
      //  scLang.class = ''
      // }

      const enLang = { label: 'English', value: 'en', label_sc: '英语', label_tc: '英語', class: '' }
      const scLang = { label: 'Simplified Chinese', value: 'zh-Hans', label_sc: '简体中文', label_tc: '簡體中文', class: '' }
      const tcLang = { label: 'Tradtional Chinese', value: 'zh-Hant', label_sc: '繁体中文', label_tc: '繁體中文', class: '' }

      return [enLang, tcLang, scLang]
    },
    profile () {
      return this.$store.getters['auth/userProfile']
    },
    interestPreferences () {
      return this.$store.getters['interestpreferences/interestsList']
    }
  },
  async mounted () {
    this.$store.commit('app/startLoading')
    const ssoUid = this.$store.getters['auth/ssoUid']
    console.log('282--- ssouid', ssoUid)

    // this.showSentVerificationEmailMessage(true, true)

    try {
      await this.$store.dispatch('app/refreshSystemSettings')
    } catch (error) {
      console.log('error--', error)
    }

    axios.get(`/customers/${ssoUid}?from=myaccount`).then(response => {
      const { data } = response
      console.log('data---- ', data)
      this.$store.commit('auth/setProfile', data)
      this.initPage(data, true)
      this.setCaptcha()
      axios.get('/otpchannels').then(otpChannelResponse => {
        const otpChannelsData = otpChannelResponse.data.filter(otpChannel => otpChannel.channel === 'SMS')
        const countryCodesSendBySMS = otpChannelsData.length ? otpChannelsData[0].country_codes : []
        if (countryCodesSendBySMS && countryCodesSendBySMS.includes(data.mobile_phone_number_country_code)) {
          this.isSendDeleteMessageBySMS = true
        } else {
          this.isSendDeleteMessageBySMS = false
        }
      }).catch(error => {
        console.log('error---- otpchannels', error)
      })
    }).catch(error => {
      console.log('error----', error)
    }).finally(() => {
      this.$store.commit('app/finishedLoading')
    })
    sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'account info|detail')
  },
  beforeRouteLeave (to, from, next) {
    console.log('359----- next', to, this.hasEditUserInfo, this.hasEditPassword, this.hasEditUserSettings)
    if (this.hasEditUserInfo || this.hasEditPassword || this.hasEditUserSettings) {
      this.isShowUnsaveConfirm = true
      this.toUrl = to
      next(false)
    } else {
      next()
    }
  },
  beforeDestroy () {
    this.$message.closeAll()
  },
  methods: {
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    cancelReadOnly (onOff) {
      this.$nextTick(() => {
        const { select } = this.$refs
        const input = select.$el.querySelector('.el-input__inner')
        input.removeAttribute('readonly')
      })
    },
    getDisplayError (error) {
      if (error === 'mobile number has exists' || error === 'mobile should be unique') {
        return this.$t('profilePhoneTakenTryAnother')
      } else if (error === 'email address has exists' || error === 'Email address should be unique') {
        return this.$t('profileEmailTakenTryAnother')
      } else if (error === 'Invalid verification code') {
        return this.$t('profileInvalidVerificationcode')
      } else if (error === 'Invalid email') {
        return this.$t('profileValidemail')
      } else if (error === 'Invalid mobile number') {
        return this.$t('profileValidphoneNo')
      } else if (error === 'Origin password not correct.') {
        return this.$t('myAccountIncorrectCurrentPassword')
      } else if (error === 'New password is same with origin.') {
        return this.$t('myAccountSamePasswordError')
      } else if (error === 'Minimum 8 characters, including numbers AND capital letters') {
        return this.$t('myAccountPasswordErrormessage')
      }

      return error
      // return this.$t('generalServerError')
    },
    isNewPasswordError (error) {
      return error === 'New password is same with origin.' ||
        error === 'Minimum 8 characters, including numbers AND capital letters'
    },
    isEmailAddressError (error) {
      return error.toLowerCase().includes('email')
    },
    scrollToElementById (elementId) {
      try {
        const element = document.getElementById(elementId)
        if (element.getBoundingClientRect().top < 91) {
          window.scrollTo({ top: element.offsetTop, behavior: 'smooth' })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    isPhoneNumberError (error) {
      return error.toLowerCase().includes('mobile')
    },
    isVerificationError (error) {
      return error.toLowerCase().includes('verification code')
    },
    sendEmailCode () {
      // send email code logic
      const emailReg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/

      if (!this.email || !emailReg.test(this.email)) {
        this.emailAddressError = this.$t('profileValidemail')
        return
      }
      this.emailAddressError = null
      this.verificationError = null

      // count down for resend
      this.sendVerificationCodeStatus = '59'
      const _this = this

      const interval = setInterval(function () {
        if (isNaN(parseInt(_this.sendVerificationCodeStatus))) {
          clearInterval(interval)
          return
        }

        const phoneSeconds = parseInt(_this.sendVerificationCodeStatus) - 1

        if (phoneSeconds <= 0) {
          clearInterval(interval)
          _this.sendVerificationCodeStatus = 'ready_resend'
        } else {
          _this.sendVerificationCodeStatus = String(phoneSeconds)
        }
      }, 1000)

      // send out verification code
      this.emailAddressError = ''
      this.verificationError = ''
      axios.post(`/customers/request-verify`, {
        for: 'bind_contact',
        method: 'EMAIL_ADDRESS',
        email: this.email
      }).then(response => {
        console.log('@515', response)

        // _this.sendVerificationCodeStatus = 'verified'
      }).catch(error => {
        console.log('@605', error)
        this.showAddContactErrorMessage(error)
        this.sendVerificationCodeStatus = 'ready_resend'
      })
    },
    setVerificationEmailCountdownInterval () {
      const _this = this
      _this.interval = setInterval(function () {
        if (isNaN(parseInt(_this.sendVerificationEmailStatus))) {
          clearInterval(_this.interval)
          return
        }
        const seconds = parseInt(_this.sendVerificationEmailStatus) - 1
        if (seconds <= 0) {
          clearInterval(_this.interval)
          _this.sendVerificationEmailStatus = 'ready_resend'
        } else {
          _this.sendVerificationEmailStatus = String(seconds)
        }
      }, 1000)
    },
    setCaptcha () {
      const grecaptcha = window.grecaptcha
      const recaptchaContainer = document.getElementById('robot')
      console.log('@2053a', grecaptcha, recaptchaContainer)

      if (grecaptcha && recaptchaContainer) {
        console.log('@2053, start to do recatptcha')
        grecaptcha.enterprise.render(recaptchaContainer, {
          sitekey: process.env.VUE_APP_RECACPTCHA_V3_KEY,
          size: 'invisible'
        })
        // const footerHight = window.$('.sticky-coupon-footer.sticky-campagin-button').css('height')
        // window.$('.grecaptcha-badge').css('bottom', footerHight)
        // window.$('.grecaptcha-badge').css('clip-path', 'inset(-5px -5px 0px -5px)')
      }
    },
    validateCaptcha () {
      return new Promise((res, rej) => {
        if (!window.grecaptcha || !document.getElementById('robot').hasChildNodes()) {
          // rej(new Error('Recaptcha not available'))
          return res('Recaptcha not available')
        }
        window.grecaptcha.enterprise.ready(() => {
          window.grecaptcha.enterprise.execute({ action:
                 'login' }).then((token) => {
            console.log('@2069', token)
            return res(token)
          })
        })
      })
    },
    async sendVerificationEmail (sentBySystem = false) {
      console.log('???1', sentBySystem)
      if ((this.emailAddressVerified || !this.oldEmail || this.oldEmail !== this.email || (this.sendVerificationEmailStatus && this.sendVerificationEmailStatus !== 'ready_resend')) && !sentBySystem) {
        return
      }
      console.log('???2', sentBySystem)

      const { recaptchaOpen, emailAddressVerificationCountdown } = this.$store.getters['app/getSystemSettings']
      this.emailAddressVerificationCountdown = emailAddressVerificationCountdown

      this.sendVerificationEmailStatus = emailAddressVerificationCountdown
      this.setVerificationEmailCountdownInterval()

      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'account_info_email_verified_request', 'onclick')

      if (sentBySystem) {
        return
      }

      // recaptcha verify
      let recaptchaString = null

      if (recaptchaOpen) {
        const token = await this.validateCaptcha()

        try {
          const { data } = await axios.post(`/customers/verify-recaptcha`,
            { token, for: 'acquire' }
          )
          console.log('@2260b', data)
          recaptchaString = data.recaptcha_verify_credential
          if (!recaptchaString) {
            this.isShowRecaptchaFail = true
            this.recaptchaFailTitle = this.$t('recaptcha_low_score_title')
            this.recaptchaFailContent = this.$t('recaptcha_low_score_content')
          } else {
            this.isShowRecaptchaFail = false
          }
        } catch (error) {
          this.isShowRecaptchaFail = true
          this.recaptchaFailTitle = this.$t('recaptcha_low_score_title')
          this.recaptchaFailContent = this.$t('recaptcha_fail_content')
          console.log('@2269 error while getting recaptcha', error)
        }
      }
      if (this.isShowRecaptchaFail) {
        return
      }
      console.log('@2260', recaptchaString)

      // send email
      try {
        await axios.post(`/customers/email_address_verification`,
          { recaptcha_verify_credential: recaptchaString }
        )
      } catch (error) {
        console.log('error while send email_address_verification', error)
        if (error.response.data.code === 'error_email_unique') {
          this.emailAddressError = error.response.data.display_error_content
        }
      }
    },
    changeBirth () {
      this.isBirthDayChanged = true
      this.setHasEditUserInfo()
    },
    isNumber (evt) {
      console.log('evt: ', evt.target.value)
      let key = evt.which
      const keyChar = evt.target.value.substr(-1)
      console.log('key cahr', keyChar)
      if (key === 229) {
        key = keyChar.charCodeAt(0)
      }

      if (key === 9 || key === 8 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
        console.log('@98----')
        return true
      }
      evt.preventDefault()
      return false
    },
    dischardChange () {
      this.isShowUnsaveConfirm = false
      this.hasEditUserInfo = false
      this.hasEditPassword = false
      this.hasEditUserSettings = false
      pushRouterWithQuery(this.$router, this.toUrl)
    },
    saveChanges () {
      this.saveAccountPassword()
      this.saveAccountInfo()
      this.saveAccountSettings()
      this.isShowUnsaveConfirm = false
    },
    setHasEditUserInfo () {
      console.log('@719', this.oldFirstName, this.fname)
      if (this.oldResidence !== this.residence ||
        this.oldFirstName !== this.fname ||
        this.oldLastName !== this.lname ||
        this.oldNickName !== this.nickname ||
        this.oldSalutation !== this.salutation ||
        this.oldMobilePhone !== this.mobilePhone ||
        this.oldCountryCode !== this.countryCode ||
        // this.verificationCode ||
        this.oldEmail !== this.email ||
        this.birth !== this.oldBirth ||
        this.oldDirectMarketing !== this.directMarketing) { this.hasEditUserInfo = true } else {
        this.hasEditUserInfo = false
      }

      if (this.birth !== this.oldBirth) {
        this.isBirthDayChanged = true
      }
      console.log('change user info', this.hasEditUserInfo, this.verificationCode)
    },
    async updateUserInfo () {
      if (!this.hasEditUserInfo || this.isSaveUser) {
        return
      }
      this.isSaveUser = true

      const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      const emailReg2 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.email && (!emailReg.test(this.email) || !emailReg2.test(this.email))) {
        this.emailAddressError = this.$t('profileValidemail')
        this.scrollToElementById('emaill-address-field')

        const profile = this.$store.getters['auth/userProfile']

        let activityType = ''
        if (profile.email_address && this.email) {
          activityType = 'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_UPDATE_FAIL_INVALID'
        } else if (!profile.email_address && this.email) {
          activityType = 'CUSTOMER_PROFILE_UPDATE_WITH_EMAIL_ADD_FAIL_INVALID'
        }

        if (activityType) {
          await this.logCustomerActivityLog(activityType, { email_address: this.email })
        }
        this.isSaveUser = false
        return
      }

      this.emailAddressError = null

      if (this.isBirthDayChanged) {
        this.isShowBirthConfirm = true
        this.isSaveUser = false
      } else {
        this.saveAccountInfo()
      }
    },
    areSetsEqual (a, b) {
      return a.size === b.size && [...a].every(value => b.has(value))
    },
    setHasEditUserSettings () {
      const interestPreferenceSet = new Set(this.interestPreference)
      const oldInterestPreferenceSet = new Set(this.oldInterestPreference)
      console.log('set edit user settings', interestPreferenceSet, oldInterestPreferenceSet)
      if (!this.areSetsEqual(interestPreferenceSet, oldInterestPreferenceSet) || this.languagePreference !== this.oldLanguagePreference) {
        this.hasEditUserSettings = true
      } else {
        this.hasEditUserSettings = false
      }
    },
    async logCustomerActivityLog (activityType, content = {}) {
      try {
        const ssoUid = this.$store.getters['auth/ssoUid']
        const response = await axios.post(`/customers/${ssoUid}/activity_logs`, { activity_type: activityType, ...content })
        console.log('@logCustomerActivityLog', response)
      } catch (error) {
        console.log('@logCustomerActivityLog', error)
      }
    },
    saveAccountPassword () {
      if (this.newPassword.length < 8 && this.newPassword.length > 0) {
        this.hasPasswordError = true
        this.scrollToElementById('new-password-field')
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'account_change_password_fail', 'onclick')
        this.logCustomerActivityLog('PASSWORD_UPDATE_FAIL')
        return
      }
      this.hasPasswordError = false
      this.currentPasswordError = false
      this.newPasswordError = ''
      if (this.newPassword !== this.confirmNewPassword) {
        this.hasConfirmPasswordError = true
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'account_change_password_fail', 'onclick')
        this.logCustomerActivityLog('PASSWORD_UPDATE_FAIL')
        return
      }
      this.hasConfirmPasswordError = false
      if (this.hasEditPassword) {
        console.log('commit password', this.newPassword)
        const ssoUid = this.$store.getters['auth/ssoUid']
        axios.put(`${process.env.VUE_APP_SSO_URL}/users/${ssoUid}/password`, {
          old_password: this.currentPassword,
          new_password: this.newPassword
        }).then(response => {
          console.log('response----', response)
          this.newPassword = ''
          this.confirmNewPassword = ''
          this.currentPassword = ''
          this.showSuccessfulMessage()
          this.hasEditPassword = false
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'account_change_password_success', 'onclick')
          sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'account info|update password success')
        }).catch(error => {
          const { data } = error.response
          console.log('error password', data)
          if (this.isNewPasswordError(data.detail)) {
            this.newPasswordError = this.getDisplayError(data.detail)
            this.hasPasswordError = true
            this.scrollToElementById('new-password-field')
          } else {
            this.currentPasswordError = this.getDisplayError(data.detail)
            this.scrollToElementById('current-password-field')
          }
          sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'account_change_password_fail', 'onclick')
        })
      }
    },
    async saveAccountInfo () {
      if (!this.hasEditUserInfo) {
        return
      }

      console.log('@695', this.verificationCode)
      const prevVerificationCode = this.verificationCode
      const payload = {
        nickname: this.nickname,
        first_name: this.fname,
        last_name: this.lname,
        mobile_phone_number_country_code: this.countryCode,
        mobile_phone_number_subscriber_number: this.mobilePhone,
        verification_code: this.verificationCode,
        salutation: this.salutation,
        date_of_birth: this.birth,
        residence: this.residence ? parseInt(this.residence) : '',
        email_address: this.email,
        has_agreed_direct_marketing: this.directMarketing
      }
      try {
        const response = await this.saveUser(payload)
        this.hasEditUserInfo = false
        const { data } = response
        console.log('???', data)
        const hasSendEmailAddressVerificationEmail = data && data.has_send_email_address_verification_email
        if (hasSendEmailAddressVerificationEmail) {
          if (this.interval) {
            clearInterval(this.interval)
          }
          this.sendVerificationEmail(true)
        }
        this.showSuccessfulMessage(hasSendEmailAddressVerificationEmail)
        this.phoneNumberError = null
        this.verificationError = null
        if (prevVerificationCode) { this.sendVerificationCodeStatus = 'verified' }
        sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'account info|update account information success')
      } catch (error) {
        console.log('@737: error: ', error)
        const { data } = error.response
        if (data) {
          if (data.mobile_phone_number_subscriber_number) {
            this.phoneNumberError = data.mobile_phone_number_subscriber_number[0]
          }
          if (data.nickname) {
            this.nickNameError = data.nickname[0]
            this.scrollToElementById('nickname-field')
          }
          if (data.salutation) {
            this.salutationError = data.salutation[0]
          }
          if (data.residence) {
            this.residenceError = data.residence[0]
          }
          if (data.detail || data.message) {
            this.showAddContactErrorMessage(error)
          }
          if (data.email_address) {
            this.emailAddressError = data.email_address[0]
            this.scrollToElementById('emaill-address-field')
          }
        }
      } finally {
        this.isShowBirthConfirm = false
        this.isSaveUser = false
      }
    },
    async saveUser (payload) {
      const ssoUid = this.$store.getters['auth/ssoUid']
      console.log('282--- ssouid', ssoUid)
      const lang = this.$i18n.locale
      const response = await axios.patch(`/customers/${ssoUid}?lang=${lang}`, payload)
      const { data } = response
      this.$store.commit('auth/setProfile', data)
      this.initPage(data)
      return response
    },
    async saveAccountSettings () {
      if (!this.hasEditUserSettings) {
        return
      }
      console.log('tihs. language', this.languagePreference, this.directMarketing, this.interestPreference)
      const payload = {
        preferred_message_language: this.languagePreference,
        interest_preferences: this.interestPreference
      }
      try {
        await this.saveUser(payload)
        this.showSuccessfulMessage()
        this.hasEditUserSettings = false
        sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'account info|update account setting success')
      } catch (error) {
        console.log('402---- error: ', error)
      }
    },
    isInt (n) {
      return Number(n) === n && n % 1 === 0
    },
    initPage (data, firstLoad = false) {
      console.log('@589', data)
      const {
        first_name,
        last_name,
        email_address,
        email_address_verified,
        email_address_message,
        nickname,
        mobile_phone_number_country_code,
        mobile_phone_number_subscriber_number,
        date_of_birth,
        preferred_message_language,
        interest_preferences,
        salutation,
        // address,
        residence,
        has_agreed_direct_marketing,
        membership_id,
        sign_up_method,
        wechat_linked } = data
      this.fname = first_name
      this.oldFirstName = first_name
      this.lname = last_name
      this.oldLastName = last_name
      this.email = email_address
      this.oldEmail = email_address || ''
      this.emailAddressVerified = email_address_verified
      this.nickname = nickname
      this.oldNickName = nickname
      this.countryCode = mobile_phone_number_country_code || '852'
      this.oldCountryCode = this.countryCode
      this.mobilePhone = mobile_phone_number_subscriber_number
      this.oldMobilePhone = mobile_phone_number_subscriber_number
      this.birth = date_of_birth
      this.oldBirth = date_of_birth
      this.signUpMethod = sign_up_method
      if (residence) {
        if (residence.id) {
          this.residence = String(residence.id)
        } else if (residence.constructor === Object) {
          this.residence = ''
        } else {
          this.residence = String(residence)
        }
      } else {
        this.residence = ''
      }
      this.oldResidence = this.residence

      if (date_of_birth) {
        this.hasBirth = true
      }
      if (interest_preferences) {
        const dealedInterestPreferences = []
        interest_preferences.map(item => {
          if (!this.isInt(item)) {
            dealedInterestPreferences.push(item.id)
          } else {
            dealedInterestPreferences.push(item)
          }
        })
        console.log('625------ dealedInterestPreferences', dealedInterestPreferences)
        this.interestPreference = dealedInterestPreferences
        this.oldInterestPreference = dealedInterestPreferences
      }
      this.directMarketing = has_agreed_direct_marketing
      this.oldDirectMarketing = has_agreed_direct_marketing
      this.languagePreference = preferred_message_language
      this.oldLanguagePreference = preferred_message_language
      this.salutation = salutation
      this.oldSalutation = salutation
      this.membershipId = membership_id
      this.wechatLinked = wechat_linked

      this.emailAddressError = email_address_message

      if (!this.oldEmail || this.emailAddressVerified || this.emailAddressError) {
        this.sendVerificationEmailStatus = null
      } else if (firstLoad) {
        // if (data.has_send_email_address_verification_email) {
        //   this.showSentVerificationEmailMessage(false, true)
        // }
        // get time from localstorage, if need count, then setInterval, else skip
        let countdownTime = 0
        const nowTime = new Date().getTime()
        if (data.has_send_email_address_verification_email && data.send_email_address_verification_email_timestamp) {
          const sendVerificationEmailEndTime = new Date(parseFloat(data.send_email_address_verification_email_timestamp) * 1000)
          const { emailAddressVerificationCountdown } = this.$store.getters['app/getSystemSettings']
          sendVerificationEmailEndTime.setSeconds(sendVerificationEmailEndTime.getSeconds() + emailAddressVerificationCountdown)
          countdownTime = parseInt((sendVerificationEmailEndTime.getTime() - nowTime) / 1000)
        }
        if (countdownTime && countdownTime > 0) {
          this.sendVerificationEmailStatus = countdownTime
          this.setVerificationEmailCountdownInterval()
        } else {
          this.sendVerificationEmailStatus = null
        }
      }
    },
    unSave () {
      this.isShowUnsaveConfirm = true
    },
    birthConfirm () {
      this.isShowBirthConfirm = true
    },
    confirmDeleteAccount () {
      console.log('5000 delete account')
      // send delete email

      const ssoUid = this.$store.getters['auth/ssoUid']
      axios.delete(`/customers/${ssoUid}`).then(response => {
        console.log('503--- respones:', response)
        sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'account info|account deletion success')
      }).then(error => {
        console.log('error-', error)
      })
      this.isShowDeleteConfirm = false
      this.isShowVerifyConfirm = true
    },
    closeVerifyConfirm () {
      this.isShowVerifyConfirm = false
      this.logoutUser()
    },
    beforeCloseVerifyConfirm (done) {
      this.logoutUser()
      return done
    },
    verifyConfirm () {
      this.isShowVerifyConfirm = true
    },
    showSuccessfulMessage (hasSendEmailAddressVerificationEmail = false) {
      const imageUrl = require('@/assets/images/notification-success-icon.png')
      let offset = 77
      if (window.$('.is-vue-mobile').length) {
        offset = 73
      }
      if (window.$('.nav').height() === 0) {
        offset = 10
      }
      this.$message({
        dangerouslyUseHTMLString: true,
        message: hasSendEmailAddressVerificationEmail ? `<strong>${this.$t('myAccountSuccessfullyUpdatedWithEmail')}</strong>` : `<strong><img class='icon' src='${imageUrl}'>${this.$t('myAccountSuccessfullyUpdated')}</strong>`,
        customClass: `account-success ${this.$t(hasSendEmailAddressVerificationEmail ? 'account-success-with-email' : '')}`,
        type: '',
        offset: offset,
        duration: 5000
      })
    },
    showAddContactErrorMessage (error) {
      const errorCode = this.getErrorCode(error)
      const errorMessage = this.getDisplayError(errorCode)

      if (this.isVerificationError(errorCode)) {
        this.verificationError = errorMessage
      } else if (this.isPhoneNumberError(errorCode)) {
        this.phoneNumberError = errorMessage
      } else {
        this.emailAddressError = errorMessage
      }
    },
    sendPhoneCode () {
      // send phone code logic

      if (!this.mobilePhone || !this.countryCode || String(this.mobilePhone).length < 5 || isNaN(parseInt(this.mobilePhone))) {
        this.phoneNumberError = this.$t('profileValidphoneNo')
        return
      }
      this.phoneNumberError = null
      this.verificationError = null

      // count down for resend
      this.sendVerificationCodeStatus = '59'
      const _this = this

      const interval = setInterval(function () {
        if (isNaN(parseInt(_this.sendVerificationCodeStatus))) {
          clearInterval(interval)
          return
        }

        const phoneSeconds = parseInt(_this.sendVerificationCodeStatus) - 1

        if (phoneSeconds <= 0) {
          clearInterval(interval)
          _this.sendVerificationCodeStatus = 'ready_resend'
        } else {
          _this.sendVerificationCodeStatus = String(phoneSeconds)
        }
      }, 1000)

      // send out verification code
      this.phoneNumberError = ''
      this.verificationError = ''
      axios.post(`/customers/request-verify`, {
        for: 'bind_contact',
        method: 'MOBILE_PHONE_NUMBER',
        country_code: this.countryCode,
        mobile_number: this.mobilePhone
      }).then(response => {
        console.log('@729', response)

        // _this.sendVerificationCodeStatus = 'verified'
      }).catch(error => {
        this.showAddContactErrorMessage(error)
      })
    },
    getErrorCode (error) {
      let errorCode = 'unknown error'
      const data = error && error.response && error.response.data ? error.response.data : {}
      if (data.detail) {
        errorCode = data.detail
      } else if (data.message) {
        errorCode = data.message
      }
      console.log('verify error error: ', error, error.response, errorCode)
      return errorCode
    },
    getLabel (item) {
      const lang = this.$i18n.locale
      if (lang === 'zh-s' && item.label_sc) {
        return item.label_sc
      }

      if (lang === 'zh-t' && item.label_tc) {
        return item.label_tc
      }

      return item.label
    }

  }
}
</script>
<style lang="scss">

.document-my-account {
  .title-tip{
    div{
      color: #E2002C;
      margin-right: 8px;
      font-weight: bold;
      margin-top: 3px;
    }

    div.zh{
      margin-left: 8px;
    }
  }
}
</style>
<style scoped lang='scss'>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.red{
  color: #E2002C;
  margin-left: 10px;
  line-height: 18px;
}

.el-card.my-account-card{
  padding: 10px;
  box-shadow: 0px 2px 9px rgba(224, 224, 224, 0.34);
  border-radius: 8px;
  margin-top: 30px;
}
.my-account-card:last-child{
  margin-bottom: 56px;
}
.columns{
  margin: 0px;
}
.page-title{
  font-weight: bold;
  font-size: 24px;
  color: #000000;
  margin-top: 30px;
}
.delete-account .account-item{
  color: #7D7D7D;
}
.title-tip{
  font-weight: 400;
  font-size: 13px;
  padding-left: 15px;
  line-height: 18px;
  color: #4A4A4A;
  display: flex;
  align-items: center;
  margin-top: -22px;
  margin-bottom: 15px;
  div{
    color: #E2002C;
    margin-right: 8px;
    font-weight: bold;
    margin-top: 3px;
  }
}
.optional{
  font-weight: 600;
  font-size: 16px;
  color: #999999;
  margin-left: 3px;
}
.login-methods{
  .row.mobile{
    margin-top: 0px;
  }
  .row div{
    margin-left: 13px;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  .row.wechat div{
    margin-left: 7px;
  }
  .columns:last-child{
    margin-bottom: -15px;
  }
  hr{
    width: calc(100% + 24px);
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: -12px;
    margin-right: -12px;
    background: #EEEEEE;
    height: 1px;
  }
}
.my-account-card{
  .mobile-no{
    height: 100%;
  }
  .verification-code{
    margin-left: 0px;
    margin-top: 23px;
  }
  .phone .control.has-icons-right .input{
    padding-right: 100px;
  }
  .control.has-icons-right .input:focus ~ .send-code.icon{
    color: #E2002C;
  }
  .control.has-icons-right .send-code{
    width: 100px;
    height: 100%;
    color: #E2002C;
    font-weight: 600;
    font-size: 15px;
    bottom: 0;
    cursor: pointer;
    pointer-events: auto;
  }
  .control.has-icons-right .send-code.wait-resend {
    color: #cccccc;
  }
  .control.has-icons-right .send-code-img{
    width: 20px;
  }
  .password-visible{
    cursor: pointer;
  }
  span.password-visible{
    pointer-events: unset;
  }
  .password-visible-icon{
    width: 22px;
    height: 22px;
  }
  .password-invisible-icon{
    width: 16px;
    height: 12px;
  }
  .account-title{
    font-weight: bold;
    font-size: 20px;
    margin: unset;
    color: #05218A;
  }
  .account-item{
    flex-flow: column;
  }
  .account-item .disabled-item{
    color:#999999;;
  }
  .account-item.row{
    display: flex;
    flex-flow: row;
    align-items: center;
  }
  .label{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0px;
  }
  .point{
    color: red;
    margin-left: 5px;
  }
  .input{
    box-shadow: none;
    font-weight: 600;
    font-size: 18px;
    padding: 0;
    color:#000000;
    width: 100%;
    opacity: 1;
  }
  .disable-input {
    background: #fff;
    -webkit-text-fill-color: #000;
  }
  .item-container{
    flex-wrap: wrap;
  }
  .no-border{
    border: none;
  }
  .under-border{
    border: none;
    border-bottom: solid 1px #E1DFE0;
    border-radius: 0px
  }
  .item-tip, .item-prompt{
    font-size: 13px;
    color: #4A4A4A;
    margin-top: 3px;
  }
  .mobile{
    width: 100%;
    margin-top: 15px;
  }
  .account-datePicker{
    width: 100%;
    margin-top: 15px;
  }
  .update{
    background: rgba(226, 0, 44, 0.3);
    border-radius: 41px;
    border: none;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    height: 48px;
    width: 154px;
    margin-top: 25px;
    cursor: pointer;
  }
  .delete-bt{
    border-radius: 41px;
    height: 48px;
    width: 154px;
    font-weight: 600;
    font-size: 18px;
  }
  .directMarketing-tip{
    font-weight: 600;
    font-size: 15px;
    margin-left: 30px;
    margin-top: 5px;
  }
  .account-success{
    height: 20px;
  }
  .password-error .item-tip{
    color: #E2002C;
  }
  .password-error input{
    color: #E2002C;
  }
  .confirm-password-tip{
    display: none;
  }
  .password-error .confirm-password-tip{
    display: block;
  }
  .emaill-address-field {
    .item-bottom-prompt {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 4px 8px;
      margin-top: 8px;

      img {
        width: 18px;
        height: 18px;
        margin-right: -4px;
      }
      span {
        font-family: Open Sans;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      }
      span.btn {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: #E2002C;
        cursor: pointer;
      }
      span.btn-disabled {
        opacity: 0.6;
        cursor: auto;
      }
    }
  }
}
.is-vue-desktop{
  .login-methods{
    .row{
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      height: 62px;
      margin-right: 30px;
      width: calc(39.5% - 15px);
      margin-bottom: 12px;
    }
  }
  .account-item{
    flex: none;
    width: 39.5%;
    min-width: 306px;
  }
  .account-item.is-full{
    flex: none;
    width: 73%;
  }
  .column{
    padding: 15px;
  }
  .account-title{
    margin-left: 15px;
    margin-bottom: 22px;
  }
  .last-card{
    margin-bottom: 56px;
  }
  .el-card.my-account-card{
    padding: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .update, .delete-bt{
    margin-left: 15px;
  }
  .mobile{
    display: none;
  }
}

.is-vue-mobile{
  .account-item.m-half{
    width: 50%;
  }
  .item-container{
    display: flex;
  }
  .page-title{
    margin-top: 0px;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    background: #FFFFFF;
  }
  .account-item{
    flex: none;
    width: 100%;
  }
  .el-card.my-account-card{
    padding: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: 8px;
    border-radius: 0px;
    box-shadow: none;
    border: none;
  }
  .column{
    padding: 12px;
  }
  .account-title{
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .columns:not(:last-child){
    margin-bottom: 12px;
  }
  .last-card{
    margin-bottom: 25px;
  }
  .update, .delete-bt{
    margin-left: 12px;
  }
  .account-dialog-title{
    font-size: 20px;
    text-align: center;
  }
  .desktop{
    display: none;
  }
  .el-dialog__body .account-dialog-message{
    font-size: 14px;
  }
  .my-account .account-dialog-cancel, .my-account .account-dialog-save{
    font-size: 18px;
    min-width: 113px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .my-account .account-dialog-cancel{
    margin-right: 10px;
  }
}
</style>
<style lang='scss'>
.el-select-dropdown__wrap .hide{
  display: none;
}
.my-account {
  .account-item {
    .el-checkbox-button.is-focus .el-checkbox-button__inner {
      border-color: #DADADA;
    }
  }
  .account-select{
    width: 100%;
  }
  .account-select input{
    border: none;
    border-bottom: solid 1px #E1DFE0;
    border-radius: 0px;
    padding-left: 0px;
    font-weight: 600;
    font-size: 18px;
    height: 100%;
    min-height: 2.5em;
    color:#000;
  }
  .account-select .el-input{
    height: 100%;
  }
  .account-select.el-select .el-input .el-select__caret{
    color: #E2002C;
    font-weight: 900;
  }
  .account-datePicker input{
    border: none;
    border-bottom: solid 1px #E1DFE0;
    border-radius: 0px;
    height: 45px;
    font-weight: 600;
    font-size: 18px;
    color:#000;
    cursor: pointer;
    padding-left: 0px !important;
  }
  .account-datePicker .cell{
    text-align: center;
  }
  .el-month-table td.today .cell{
    color: #606266 !important;
    font-weight: normal !important;
  }
  .el-month-table td.today .cell:hover {
    color: #E2002C !important;
  }
  .account-checkbox-group label{
    margin-right: 12px;
    border: none;
    border-radius: 4px;
  }
  .account-checkbox-group label span{
    font-weight: 600;
    font-size: 15px;
    color: #999999;
    border: 2px solid #DADADA;
    border-radius: 4px;
  }
  .account-checkbox-group .el-checkbox-button{
    margin-top: 9px;
  }
  .account-checkbox-group .el-checkbox-button:first-child .el-checkbox-button__inner{
    border: 2px solid #DADADA;
    border-radius: 4px;
  }
  .account-checkbox-group .el-checkbox-button:last-child .el-checkbox-button__inner{
    border: 2px solid #DADADA;
    border-radius: 4px;
  }
  .account-checkbox-group .el-checkbox-button.is-checked .el-checkbox-button__inner{
    border: solid 2px #E2002C;
    color: #E2002C;
    background-color: white;
    box-shadow: none;
  }
  .account-checkbox-group .el-checkbox-button.is-checked:first-child .el-checkbox-button__inner{
    border: solid 2px #E2002C;
    color: #E2002C;
    background-color: white;
  }
  .account-checkbox-group .el-checkbox-button.is-checked:last-child .el-checkbox-button__inner{
    border: solid 2px #E2002C;
    color: #E2002C;
    background-color: white;
    box-shadow: none;
  }
  .directMarketing .el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: white;
  }
  .directMarketing .el-checkbox__inner::after{
    height: 10px;
    left: 6px;
    border: 1px solid #E2002C;
    border-left: 0;
    border-top: 0;
  }
  .directMarketing .el-checkbox__input.is-checked + .el-checkbox__label{
    color: #000;
  }
  .directMarketing .el-checkbox__label{
    font-weight: 600;
    font-size: 18px;
    color: #000000;
  }
  .directMarketing .el-checkbox__inner{
    border-color: #61666A;
    height: 16.8px;
    width: 16.8px;
    margin-top: -3px;
  }
  .el-dialog__header{
    padding-left: 30px;
    padding-top: 30px;
  }
  .el-dialog__body{
    padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 5px;
  }
  .el-dialog__footer{
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 30px;
    padding-top: 0px;
  }
  .account-dialog-cancel{
    padding: 12px 24px;
    border-radius: 100px;
    height: 51px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 10px;
  }
  .account-dialog-save{
    border-radius: 100px;
    padding: 12px 24px;
    height: 51px;
    font-weight: bold;
    font-size: 20px;
    min-width: 134px;
    margin-top: 10px;
  }
  .account-dialog-message{
    font-size: 15px;
    color: #404040;
    word-break: break-word;
  }
  .account-dialog-title{
    font-weight: bold;
    font-size: 30px;
    color: #4A4A4A;
  }
  .el-dialog__close{
    font-size: 26px;
  }
  .my-account-card .el-card__body{
    padding: 0px;
  }
}

.is-vue-mobile .my-account{
  .el-dialog{
    width: calc(100% - 40px) !important;
    max-width: 500px;
    margin-top: calc(50vh - 150px);
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  .el-dialog .account-dialog-title{
    margin-top: 10px;
  }
  .el-dialog__headerbtn{
    display: none;
  }
  .el-dialog__body{
    text-align: center;
  }
  .dialog-footer{
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  .title-tip{
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 8px;
  }
}

.account-success.el-message{
    background-color: #E6F4E7;;
    width: 228px;
    min-width: 228px;
    min-height: 50px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 19px;
    padding-bottom: 15px;
    border: 1px solid rgba(128, 189, 120, 0.1);
    border-radius: 6px;
  }
  .account-success.el-message strong{
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    color: #000000;
  }
  .account-success.el-message .icon{
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }

.account-success-with-email.el-message {
  width: auto;
}
.account-success-with-email.el-message strong {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #4A4A4A;
  align-items: flex-start;
}
</style>

<style>
.el-date-picker .el-month-table td.disabled .cell {
  background: none;
}
.el-date-picker__header.el-date-picker__header--bordered{
  display: none;
}
.el-date-picker .el-month-table td.today .cell{
  color: unset;
  font-weight:unset;
}
.el-date-picker .el-month-table .cell{
  text-align: center;
}
.el-date-picker .el-picker-panel__content{
  margin-bottom: 0px;
}
.document-my-account.has-one-trust .el-dialog{
    margin-top: calc(40vh - 150px) !important;
}
</style>
