<template>
  <div class="add-to-home-screen-contain">
    <div class="add-to-home-screen">
      <div v-if="mobileType==='iOS'" class="text">{{ $t('generalAddtoHomescreenClick') }} <img
        srcset="/img/share.1x.png, /img/share.2x.png 2x, /img/share.3x.png 3x"
        src="/img/share.2x.png"
      > {{ $t('generalAddtoHomescreenSuffix') }} <b class="red">{{ $t('generalAddtoHomescreenAdd') }}</b> {{ $t('generalAddtoHomescreenAffix') }}</div>
      <div v-else-if="mobileType==='Android' && isFirefox" class="text">{{ $t('generalAddtoHomescreenClick') }} <img
        class="three"
        srcset="/img/three.1x.png, /img/three.2x.png 2x, /img/three.3x.png 3x"
        src="/img/three.2x.png"
      > {{ $t('generalAddtoHomescreenWebSuffix') }}  <b class="red">{{ $t('generalAddtoHomescreenWebInstall') }} </b> {{ $t('generalAddtoHomescreenWebTo') }} {{ $t('generalAddtoHomescreenWebAdd') }} {{ $t('generalAddtoHomescreenWebDescription') }}</div>
      <div v-else-if="mobileType==='Android'" class="text">{{ $t('generalAddtoHomescreenClick') }} <img
        class="three"
        srcset="/img/three.1x.png, /img/three.2x.png 2x, /img/three.3x.png 3x"
        src="/img/three.2x.png"
      > {{ $t('generalAddtoHomescreenAppSuffix') }}  <b class="red">{{ $t('generalAddtoHomescreenAppInstall') }}</b> {{ $t('generalAddtoHomescreenAppTo') }} {{ $t('generalAddtoHomescreenAppAdd') }} {{ $t('generalAddtoHomescreenAppAffix') }}</div>
      <img
        class="close"
        srcset="/img/close.1x.png, /img/close.2x.png 2x, /img/close.3x.png 3x"
        src="/img/close.2x.png"
        data-aid="add_to_home_screen_click"
        @click="close"
      >
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      campaignList: [],
      isShowAddToHomeScreen: false,
      isShowCookiePolicy: false
    }
  },
  computed: {
    isFirefox () {
      var { userAgent } = window.navigator
      if (userAgent.indexOf('Firefox') >= 0) {
        return true
      }
      return false
    },
    mobileType () {
      return this.judgeClient()
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }

}
</script>

<style lang="scss" scoped>
.add-to-home-screen-contain{
  margin-bottom: 15px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;

  .add-to-home-screen{
    display: flex;
    flex-flow: row;
    align-items: center;
    max-width: 540px;
    background: #FFF1F1;
    border: 1px solid #FFCECE;
    font-size: 12px;
    color: #000000;
    border-radius: 100px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 36px;
    padding-right: 23px;
  }
  .close{
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
  .text{
    margin-right: 6px;
  }
  .red{
    color: #FF0015;
  }
  .share{
    width: 16px;
    height: 22px;
    margin-left: 2px;
    margin-right: 2px;
  }
  .three{
    width: 3px;
    height: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }
}
</style>
