<template>
  <div class="applicable-store-mobile">
    <applicable-store :applicablestoreslist="applicableStoresList" :pagetype="applicableStoresPageType" :index="position" @pagetypechange="pageTypeChange" />
  </div>
</template>
<script>
import ApplicableStore from '@/components/ApplicableStore'
import utilsMixin from '@/mixins/utilsMixin'

export default {
  name: 'ApplicableStoreForMobile',
  components: {
    ApplicableStore
  },
  mixins: [utilsMixin],
  data () {
    return {
      applicableStoresPageType: 'detail'
    }
  },
  computed: {
    applicableStoresList () {
      const campaignId = this.$route.query.campaignId
      const couponId = this.$route.query.couponId
      if (campaignId) {
        const campaginData = this.$store.getters['campaigns/campaignDetail'](campaignId)
        const stores = campaginData && campaginData.detail && campaginData.detail.stores ? campaginData.detail.stores : []
        return this.formatApplicableStores(stores)
      } else {
        const couponData = this.$store.getters['coupons/couponDetail'](couponId)
        console.log(' coupons data', couponData)
        const stores = couponData && couponData.template ? couponData.template.applicable_stores : []
        return this.formatApplicableStores(stores)
      }
    },
    position () {
      const storeId = this.$route.query.storeId
      let position = this.$route.query.index
      this.applicableStoresList.find((item, index) => {
        if (parseInt(item.id) === parseInt(storeId)) {
          position = index
          return true
        }
        return
      })
      return position
    }
  },
  mounted () {
    this.$store.commit('app/setNavType', 'back_language_menu')
    if (this.$store.getters['app/isMobile']) {
      this.$store.commit('app/hideFooter')
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$store.commit('app/showFooter')
  },
  methods: {
    pageTypeChange (type) {
      this.applicableStoresPageType = type
    }
  }
}
</script>
