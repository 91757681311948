
export const interestpreferences = {
  namespaced: true,
  state: {
    interests: []
  },
  mutations: {
    initInterests (state, interests) {
      state.interests = interests
    }
  },
  getters: {
    interestsList: (state) => {
      return state.interests
    }
  }
}

export default interestpreferences
