<template>
  <el-dialog
    :visible="value"
    lock-scroll
    :width="$store.getters['app/isMobile']? 'calc(100vw - 26px)': '1024px'"
    class="tnc-dialog"
    @close="$emit('input', false)"
  >
    <span slot="title">
      <div class="tnc-title">
        {{ title || $t('genericTerms&ConditionsGTConditions') }}
      </div>
    </span>
    <span
      class="tnc-content toastui-editor-contents"
      v-html="content"
    />
  </el-dialog>
</template>
<script>
export default {
  name: 'TermsDialog',
  props: {
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.is-vue-desktop .tnc-dialog {
  padding-right: 20px
}

.tnc-dialog{

  .tnc-title{
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
  }

  .tnc-content{
    font-size: 14px;
    line-height: 22px;

    color: #000000;
    text-align: left;
  }

  .el-dialog{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding-bottom: 20px;

    .el-dialog__body{
      padding-top: 8px;
      max-height: 420px;
      overflow-y: auto;
      padding-bottom: 10px;
      word-break: normal;
    }
  }
}
</style>
