<template>
  <div v-if="isShowGoodieBagImage" class="search-bar-background" />
</template>

<script>

export default {
  computed: {
    isShowGoodieBagImage () {
      return this.$store.getters['app/isShowGoodieBagImage'] && this.$router.history.current.name === 'goodie-bag-campaign-list'
    }
  }
}
</script>

<style lang="scss" scoped>
.is-vue-desktop {
  .search-bar-background {
    background: #eee;
    height: 80px;

    position: sticky;
    top: 91px;
  }
}

.is_vue_mobile .search-bar-background {
  display: none;
}

</style>

