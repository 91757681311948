<script>
export default {
  data () {
    return {
      isBackNavigation: false
    }
  },
  computed: {

  },
  methods: {

  },
  beforeRouteEnter (to, from, next) {
    let isBackNavigation = false
    if (window.event && window.event.type === 'popstate') {
      isBackNavigation = true
      console.log('@18@backendhander')
    }
    next(vm => {
      vm.isBackNavigation = isBackNavigation
    }
    )
  }
}
</script>
