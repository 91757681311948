import axios from 'axios'

export const goodiebags = {
  namespaced: true,
  state: {
    goodieBags: []
  },
  actions: {
    updateGoodieBag: async (payload, { goodieBagId, trackingCode }) => {
      const response = await axios.get(`/goodie_bags/${goodieBagId}?tracking_code=${trackingCode || ''}`)
      const goodieBag = response.data || {}
      payload.commit('updateGoodieBag', goodieBag)
    }
  },
  mutations: {
    updateGoodieBag (state, goodieBag) {
      state.goodieBags = [
        ...state.goodieBags.filter(item => item.id !== goodieBag.id),
        goodieBag
      ]
    }
  },
  getters: {
    goodieBagDetail: (state) => (id) => {
      return state.goodieBags.find(goodieBag => parseInt(goodieBag.id) === parseInt(id))
    }
  }
}

export default goodiebags
