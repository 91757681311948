<template>
  <div class="cash-vouchers-banner" :class="{'unlimited': isUnlimitedReward}">
    <div class="line credit">
      <div v-if="isUnlimitedReward" class="credit-text-unlimited">{{ $t("goodiebagUnlimitedRewardTitle") }}</div>
      <div v-else-if="isUnlimitedReward === false" class="line credit">
        <div v-if="isVoucher" class="credit-text-small">{{ $t('goodiebagStillHave') }}</div>
        <div v-else-if="isGift" class="credit-text-small">{{ $t('goodiebagYousStillHave1') }}</div>
        <div v-if="isVoucher" class="credit-count" v-html="$t('goodiebagStillHave2', {cash: cashVouchers })" />
        <div v-else-if="isGift" class="credit-count" v-html="$t('goodiebagYousStillHave2', { cash: cashVouchers })" />

        <div v-if="isVoucher" class="credit-text-small" v-html="$t('goodiebagStillHave2b')" />
        <div v-else-if="isGift" class="credit-text-small" v-html="$t('goodiebagYousStillHave2b')" />

        <div v-if="isGift" class="credit-text-small" v-html="$t('goodiebagYousStillHave4')" />

        <div v-if="isVoucher" class="credit-text-small extra-margin">{{ $t('goodiebagStillHave3', { limit: cashVouchersAssign }) }}</div>
        <div v-else-if="isGift" class="credit-text-small extra-margin">{{ $t('goodiebagYousStillHave3', { limit: cashVouchersAssign }) }}</div>
      </div>
      <div v-if="isUnlimitedReward === false" class="question-mark-icon-container">
        <el-tooltip
          class="item"
          :content="isGift ? $t('goodiebagAcquireLimitedoffer') : $t('goodiebagAcquirecredit') "
          placement="bottom"
          @mouseover.native="analyticsMouseOver()"
        >
          <!-- <img
            v-if="isUnlimitedReward"
            width="20"
            height="20"
            class="question-mark-icon question-mark-icon-unlimited"
            srcset="/img/info.1x.png, /img/info.2x.png 2x, /img/info.3x.png 3x"
            src="/img/info.2x.png"
          > -->
          <img
            width="18"
            height="18"
            class="question-mark-icon"
            srcset="/img/info.1x.png, /img/info.2x.png 2x, /img/info.3x.png 3x"
            src="/img/info.2x.png"
          >
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="goodieBagValidDateString"
      class="line alarm"
    >
      <img
        v-if="isUnlimitedReward"
        width="18px"
        srcset="/img/alarm-clock2.1x.png, /img/alarm-clock2.2x.png 2x, /img/alarm-clock2.3x.png 3x"
        src="/img/alarm-clock2.2x.png"
      >
      <img
        v-else
        width="14px"
        srcset="/img/alarm-clock.1x.png, /img/alarm-clock.2x.png 2x, /img/alarm-clock.3x.png 3x"
        src="/img/alarm-clock.2x.png"
      >
      <div v-html="goodieBagValidDateString" />
    </div>
    <div class="circle-big" />
    <div class="circle-small" />
  </div>
</template>
<script>
import { getGoodieBagCampaignSlot } from '@/utils/utils'

export default {
  name: 'GoodieBagBanner',
  props: {
    type: {
      type: [String],
      description: 'type',
      default: 'Voucher'
    },
    recordid: {
      type: [Number, String],
      description: 'recordId',
      default: null
    },
    slotid: {
      type: [Number, String],
      description: 'slotId',
      default: null
    }
  },
  computed: {
    goodieBagValidDateString () {
      // console.log('goodieBagValidDateString', this.goodieBagRecord)
      if (!this.goodieBagRecord) {
        return
      }

      const {
        coupon_acquire_start_date: startDate,
        coupon_acquire_end_date: endDate
      } = this.goodieBagRecord

      let itemAlias = 'cashVoucher'
      if (this.type === 'GIFT') {
        itemAlias = 'limitedOffer'
      }

      return this.getGoodieBagValidDateString(startDate, endDate, itemAlias)
    },
    goodieBagRecord () {
      let goodieBagRecord = {}
      if (this.recordid) {
        goodieBagRecord = this.$store.getters['goodiebagparticipationrecords/recordDetail'](this.recordid)
      }
      return goodieBagRecord
    },
    cashVouchers () {
      let cashVouchers = 0
      if (this.recordid) {
        if (this.campaignSlot) {
          cashVouchers = this.campaignSlot.owned_reward
        } else {
          const recordDetail = this.goodieBagRecord
          if (recordDetail && this.type === 'Voucher') {
            cashVouchers = recordDetail['owned_credit']
          }
          if (recordDetail && this.type === 'GIFT') {
            cashVouchers = recordDetail['owned_quota']
          }
        }
        cashVouchers = this.formatVoucherValue(cashVouchers)
      }
      return cashVouchers
    },
    cashVouchersAssign () {
      let cashVouchersAssign = 0
      if (this.recordid) {
        if (this.campaignSlot) {
          cashVouchersAssign = this.campaignSlot.assign_reward_per_link
        } else {
          const recordDetail = this.goodieBagRecord
          if (recordDetail && this.type === 'Voucher') {
            cashVouchersAssign = recordDetail['goodie_bag']['assign_credit_per_link']
          }
          if (recordDetail && this.type === 'GIFT') {
            cashVouchersAssign = recordDetail['goodie_bag']['assign_quota_per_link']
          }
        }
        cashVouchersAssign = this.formatVoucherValue(cashVouchersAssign)
      }
      return cashVouchersAssign
    },
    campaignSlot () {
      if (this.recordid) {
        return getGoodieBagCampaignSlot(this.goodieBagRecord, this.slotid)
      }
      return null
    },
    isUnlimitedReward () {
      if (this.campaignSlot) {
        if (this.campaignSlot.assign_reward_per_link === null) {
          return true
        }
        return false
      }
      return null
    },
    isVoucher () {
      return this.type === 'Voucher' && !this.isUnlimitedReward
    },
    isGift () {
      return this.type === 'GIFT' && !this.isUnlimitedReward
    }
  },
  methods: {
    formatVoucherValue (cashVoucher) {
      if (cashVoucher >= 10000) {
        cashVoucher = parseInt(cashVoucher / 1000).toString() + 'k'
      } else if (cashVoucher < 0) {
        cashVoucher = 0
      }
      return cashVoucher
    }
  }
}
</script>

<style lang="scss">
.is-vue-desktop .cash-vouchers-banner .line.alarm strong{
  font-size: unset;
}

.cash-vouchers-banner .line.alarm strong{
  font-size: 12px;
  color: #FFFFFF;
  font-weight: normal;
}
</style>

<style lang="scss" scoped>
.is-vue-desktop .cash-vouchers-banner .line .credit-count{
  margin-bottom: 0px;
}

.is-vue-desktop {
  .credit-container.cash-vouchers-banner{
    .credit-text-small{
      margin-right: 0px;
    }
    .question-mark-icon-container{
      display: flex;
      align-items: center;
      margin-top: 0px;
      img{
        margin-bottom: 5px;
      }
    }
  }
}

.is-vue-mobile .question-mark-icon-container{
  position: absolute;
  right: -7px;
}

.is-vue-mobile   .credit-text-small.extra-margin {
    margin-right: 26px;
}

.question-mark-icon-container{
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-top: 5px;
    display: none;
    img{
      filter: grayscale(100%) brightness(600%);
    }
}

.document.document-goodie-bag-campaign-list .question-mark-icon-container{
  display: flex;
}

.cash-vouchers-banner{
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 12px;
  background: linear-gradient(270deg, #E93838 10.08%, #F93838 88.52%);
  .line{
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 10;
    .credit-count{
      margin-left: 5px;
      margin-bottom: -1px;
      font-weight: 700;
    }
    img{
      margin-right: 8px;
    }
  }
  .line.alarm{
    align-items: center;
    margin-top: 7px;
    font-size: 12px;
    color: #FFFFFF;
  }
  .credit-text, .credit-text-small, .credit-text-unlimited {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    z-index: 1;
  }

  .line.credit{
    flex-wrap: wrap;
  }

  .credit-count{
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    z-index: 1;
    margin-right: 5px;
    line-height: 22px;
  }

  .circle-big {
    position: absolute;
    width: 151px;
    border-radius: 77px;
    height: 153px;
    right: 46px;
    bottom: 20px;
    background: linear-gradient(72.76deg, #F04040 21.43%, #F15252 92.74%);
  }

  .circle-small {
    position: absolute;
    border-radius: 34px;
    width: 66px;
    height: 67px;
    right: 121px;
    top: 43px;
    background: linear-gradient(72.76deg, #F04040 21.43%, #F15252 92.74%);
  }

  &.goodie-bag-campaign-desktop {
    padding-left: 14px;
    padding-right: 14px;
    .circle-big{
      bottom: 34px;
      right: -10px;
    }
    .circle-small{
      top: 16px;
      right: 51px;
    }
    .credit-count{
      font-size: 14px;
      line-height: 24px;
    }
    .credit-text, .credit-text-small, .credit-text-unlimited{
      font-size: 14px;
      margin-top: 11px;
      line-height: 27px;
    }
    .line img{
      margin-right: 6px;
      margin-top: 3px;
    }
    .line.alarm{
      font-size: 11px;
      align-items: flex-start;
    }
    .line.credit{
      flex-wrap: wrap;
      .credit-count{
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
}
.cash-vouchers-banner.unlimited {
  padding: 8px 16px;

  flex-direction: column;
  align-items: flex-start;
  .credit-text-unlimited{
    margin-right: 28px;
  }
  .line.credit {
    width: 100%;
    align-items: center;
  }
  .question-mark-icon-container{
    right: 0;
    display: flex;
  }
  .line.alarm{
    margin-top: 4px;
  }
  &.goodie-bag-campaign-desktop {
    .question-mark-icon-container{
      position: absolute;
    }
  }
}
.is-vue-desktop {
  .credit-container.cash-vouchers-banner.unlimited {
    padding: 12px 30px;

    flex-direction: column;
    align-items: flex-start;
    .credit-text-unlimited{
      margin-right: 0px;
    }
    .question-mark-icon-container{
      img{
        margin-bottom: 0px;
      }
    }
    .line.alarm {
      img {
        margin-top: 2px !important;
      }
    }
  }
}
</style>
<style lang="scss">
.cash-vouchers-banner{
  .credit-count{
    b{
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      color: #FFFFFF;
      z-index: 1;
    }
  }
  &.goodie-bag-campaign-desktop {
    .credit-count{
      b{
        font-size: 19px;
        line-height: 30px;
      }
    }
  }
}

</style>

