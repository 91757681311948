import moment from 'moment'

const isRedeemedOrExpired = (coupon) => {
  if (coupon.is_used) {
    return true
  }
  return isExpired(coupon)
}

const isRedeemed = (coupon) => {
  return coupon.is_used
}

const isExpired = (coupon) => {
  return coupon.is_expired
}

const isUnavailable = (coupon) => {
  if (coupon.effective_date) {
    return new Date() < new Date(coupon.effective_date)
  }
  if (coupon.is_forced_inactive) {
    return true
  }
  return false
}

const isAvailable = (coupon) => {
  return !isExpired(coupon) && !isRedeemed(coupon) && !isUnavailable(coupon)
}

const isRedeemedOrExpiredInThreeMonth = (coupon) => {
  return (isRedeemed(coupon) && moment(new Date()).diff(coupon.date_of_acquisition, 'months') < 3) ||
    (isExpired(coupon) && moment(new Date()).diff(coupon.expiry_date, 'months') < 3)
}

export {
  isRedeemedOrExpired,
  isUnavailable,
  isAvailable,
  isExpired,
  isRedeemed,
  isRedeemedOrExpiredInThreeMonth
}
