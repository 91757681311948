<template>
  <div v-if="pagetype==='list'" class="applicable-stores-list">
    <div class="top-select">
      <el-select
        v-model="areaValue"
        class="list-select"
        :class="{'default': areaValue === $t('storeArea')}"
        @change="districtValue=$t('storeDistrict')"
      >
        <el-option
          v-for="(item, areaIndex) in areaOptions"
          :key="item + 'area' + areaIndex"
          :label="item"
          :value="item"
        />
      </el-select>
      <el-select
        v-model="districtValue"
        :class="{'default': districtValue === $t('storeDistrict')}"
        class="list-select"
      >
        <el-option
          v-for="(item, districtIndex) in districtOptions"
          :key="item + 'district' + districtIndex"
          :label="item"
          :value="item"
        />
      </el-select>
    </div>
    <ul class="infinite-list">
      <li v-for="(item, storeIndex) in displayApplicablestoreslist" :key="item.name + storeIndex" :data-index="storeIndex" :data-aid="$route.query.campaignId?'offer_detail_stores_list_click':'coupon_detail_stores_list_click'" class="infinite-list-item applicable-stores-line" @click="listItemClick(item, storeIndex)">
        <div class="applicable-stores-left-text">
          <div class="applicable-stores-name">{{ item.name }}</div>
          <div v-if="item.address" class="applicable-stores-address">
            <img
              height="13px"
              srcset="/img/location-small.1x.png, /img/location-small.2x.png 2x, /img/location-small.3x.png 3x"
              src="/img/location-small.2x.png"
            >
            <div>{{ item.address }}</div>
          </div>
          <div v-if="item.phone" class="applicable-stores-phone">
            <img
              height="12px"
              srcset="/img/phone-small.1x.png, /img/phone-small.2x.png 2x, /img/phone-small.3x.png 3x"
              src="/img/phone-small.2x.png"
            >
            <div>{{ formatPhone(item.phone) }}</div>
          </div>
        </div>
        <img
          class="right"
          srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
          src="/img/more.2x.png"
        >
      </li>
    </ul>
  </div>
  <div v-else-if="pagetype==='detail'" class="applicable-stores-detail">
    <div class="applicable-stores-title">{{ currentItem.name }}</div>
    <div v-if="currentItem.description" class="applicable-stores-line">
      <img
        width="20px"
        srcset="/img/house.1x.png, /img/house.2x.png 2x, /img/house.3x.png 3x"
        src="/img/house.2x.png"
      >
      <div class="text store-description toastui-editor-contents" v-html="currentItem.description" />
    </div>
    <a v-if="currentItem.phone" class="applicable-stores-line" :href="'tel:' + currentItem.phone">
      <img
        width="18px"
        srcset="/img/phone.1x.png, /img/phone.2x.png 2x, /img/phone.3x.png 3x"
        src="/img/phone.2x.png"
      >
      <div class="bold">
        {{ formatPhone(currentItem.phone) }}
      </div>
      <img
        class="right desktop_hide"
        srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
        src="/img/more.2x.png"
      >
    </a>
    <a v-if="currentItem.email" class="applicable-stores-line" :href="'mailto:' + currentItem.email">
      <img
        width="18px"
        srcset="/img/email.1x.png, /img/email.2x.png 2x, /img/email.3x.png 3x"
        src="/img/email.2x.png"
      >
      <div class="bold">
        {{ currentItem.email }}
      </div>
      <img
        class="right"
        srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
        src="/img/more.2x.png"
      >
    </a>
    <div v-if="currentItem.holidayBusinessHours || (currentItem.weekBusinessHours && currentItem.weekBusinessHours.length)" class="applicable-stores-line">
      <img
        width="18px"
        srcset="/img/time.1x.png, /img/time.2x.png 2x, /img/time.3x.png 3x"
        src="/img/time.2x.png"
      >
      <div class="business-hours-contain">
        <div class="bold">{{ $t('campaignBusinessHours') }}</div>
        <div v-for="(item, i) in currentItem.weekBusinessHours" :key="i" class="business-hours">
          <div class="weekdays">{{ item.businessDay }}</div>
          <div>{{ item.businessTime }} </div>
        </div>
        <div v-if="currentItem.holidayBusinessHours" class="business-hours">
          <div>{{ currentItem.holidayBusinessHours.businessDay }}</div>
          <div>{{ currentItem.holidayBusinessHours.businessTime }}</div>
        </div>
        <div v-if="currentItem.closedDays" class="business-hours">
          <div>{{ currentItem.closedDays.businessDay }}</div>
          <div>{{ currentItem.closedDays.businessTime }}</div>
        </div>
      </div>
    </div>
    <div class="applicable-stores-line address" @click="goToMap">
      <img
        style="margin-top: 0px;"
        width="20px"
        srcset="/img/location.1x.png, /img/location.2x.png 2x, /img/location.3x.png 3x"
        src="/img/location.2x.png"
      >
      <div class="text bold">
        {{ currentItem.address }}
      </div>
      <img
        v-if="!isMiniProgram()"
        class="right"
        srcset="/img/more.1x.png, /img/more.2x.png 2x, /img/more.3x.png 3x"
        src="/img/more.2x.png"
      >
    </div>
    <div class="applicable-stores-line">
      <baidu-map v-if="isMiniProgram() || lang === 'zh-s' && (currentItem.address || currentItem.latitude)" id="baidu-container" :ak="baiduMapKey()" :zoom="15" @ready="handler">
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
      </baidu-map>
      <div v-if="!isMiniProgram() && (lang !== '' && lang !== 'zh-s')" class="google-container">
        <div class="mapouter">
          <div class="gmap_canvas">
            <iframe id="gmap_canvas" width="605px" height="334px" :src="'https://maps.google.com/maps?q=' + encodeURI(googleMapQueryString) + '&t=&z=13&ie=UTF8&iwloc=&output=embed'" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
// import backHandlerMixin from '@/mixins/backHandlerMixin'

import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { BmNavigation } from 'vue-baidu-map'
import { isWechatMiniProgram, pushRouterWithQuery } from '@/utils/utils'
import { sendAdobeManualAnalytics } from '@/utils/tracking'

export default {
  name: 'ApplicableStore',
  components: {
    BaiduMap,
    BmNavigation
  },
  mixins: [utilsMixin],
  props: {
    applicablestoreslist: {
      type: Array,
      default: null
    },
    pagetype: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    couponId: {
      type: String,
      default: ''
    },
    campaignId: {
      type: String,
      default: ''
    },
    isBackNavigationStore: {
      type: Boolean,
      default: false
    }
    /*
    districtValue: {
      type: String,
      default: () => this.$t('storeDistrict')
    },
    areaValue: {
      type: String,
      default: () => this.$t('storeArea')
    }
    */
  },
  data () {
    return {
      lang: '',
      clickedItem: null,
      areaValue: this.$t('storeArea'),
      districtValue: this.$t('storeDistrict'),
      bmLat: '',
      bmLng: ''
    }
  },
  computed: {
    options () {
      const result = {}
      result[this.$t('storeArea')] = []
      for (let i = 0; i < this.applicablestoreslist.length; i++) {
        if (this.applicablestoreslist[i].area) {
          const area = this.applicablestoreslist[i].area
          if (area && !result[area]) {
            result[area] = []
          }
          const district = this.applicablestoreslist[i].district
          if (district && !result[area].includes(district)) {
            result[area].push(district)
            result[this.$t('storeArea')].push(district)
          }
        }
      }
      return result
    },
    googleMapQueryString () {
      let query = ''
      const currentItem = this.getCurrentItem()
      console.log('@186', currentItem, currentItem.latitude, currentItem.longitude)
      if (currentItem.latitude && currentItem.longitude) {
        query = `${currentItem.latitude},${currentItem.longitude}`
      } else {
        query = `${currentItem.address}`
      }

      console.log('@194', query)

      return query
    },
    areaOptions () {
      const result = Object.keys(this.options)
      return result
    },
    districtOptions () {
      const result = this.options[this.areaValue]
      if (result && !result.includes(this.$t('storeDistrict'))) {
        result.unshift(this.$t('storeDistrict'))
      }
      return result
    },
    displayApplicablestoreslist () {
      console.log('@240c', this.isBackNavigationStore, this.applicablestoreslist,
        this.areaValue, this.districtValue)

      let result = []
      if (this.areaValue === this.$t('storeArea')) {
        result = this.applicablestoreslist
      } else {
        result = this.applicablestoreslist.filter(item => item.area === this.areaValue)
      }
      if (this.districtValue !== this.$t('storeDistrict')) {
        result = result.filter(item => item.district === this.districtValue)
      }

      console.log('@240uu', result)
      return result
    },
    currentItem () {
      return this.getCurrentItem()
    }
  },
  watch: {
    'isBackNavigationStore': function (newVal, oldVal) {
      console.log('@240d', oldVal, newVal, typeof newVal)
      if (newVal !== oldVal && newVal) {
        const { districtValue, areaValue
        } = this.$store.getters['app/getLastApplicablestoreState']

        if (districtValue && areaValue) {
          console.log('@240e', districtValue, areaValue)
          this.districtValue = districtValue
          this.areaValue = areaValue
        }
      }
    }

  },
  mounted () {
    this.lang = this.$i18n.locale
    if (this.index || this.index === 0) {
      this.clickedItem = this.applicablestoreslist[this.index]
    }

    console.log('@240b', this.isBackNavigationStore, this.applicablestoreslist)
    /*
    if (this.isBackNavigationStore) {
      const { districtValue, areaValue
      } = this.$store.getters['app/getLastApplicablestoreState']

      this.districtValue = districtValue
      this.areaValue = areaValue
    }
    */

    // if (this.pagetype === 'detail' && this.lang === 'zh-s' && this.currentItem) {
    // setTimeout(() => { this.initBaiduMap(this.currentItem.address) }, 0)
    // }
  },
  beforeDestroy () {
    console.log('@240x, before destroy', this.districtValue, this.areaValue)
  },
  methods: {
    updateLastApplicateStoreState () {
      if (this.districtValue && this.areaValue) {
        console.log('@240pp, update last state', this.districtValue, this.areaValue)
        this.$store.commit('app/setLastApplicablesStoreState',
          {
            districtValue: this.districtValue,
            areaValue: this.areaValue
          }
        )
      }
    },
    isMiniProgram () {
      return isWechatMiniProgram()
    },
    handler ({ BMap, map }) {
      if (this.currentItem && this.currentItem.latitude && this.currentItem.longitude) {
        const vm = this
        var translateCallback = function (data) {
          console.log(data)
          if (data.status === 0) {
            vm.bmLat = data.points[0].lat
            vm.bmLng = data.points[0].lng
            var bmPoint = new BMap.Point(vm.bmLng, vm.bmLat)
            map.centerAndZoom(bmPoint, 15)
            var markerbm = new BMap.Marker(bmPoint)
            map.addOverlay(markerbm)
          }
        }
        var ggPoint = new BMap.Point(this.currentItem.longitude, this.currentItem.latitude)
        var convertor = new BMap.Convertor()
        var pointArr = []
        pointArr.push(ggPoint)
        const COORDINATES_WGS84 = 1
        const COORDINATES_BD09 = 5
        convertor.translate(pointArr, COORDINATES_WGS84, COORDINATES_BD09, translateCallback)
        /**
         * 坐標常量說明：
         * COORDINATES_WGS84 = 1, WGS84坐標
         * COORDINATES_WGS84_MC = 2, WGS84的平面墨卡托坐標
         * COORDINATES_GCJ02 = 3，GCJ02坐標
         * COORDINATES_GCJ02_MC = 4, GCJ02的平面墨卡托坐標
         * COORDINATES_BD09 = 5, 百度bd09經緯度坐標
         * COORDINATES_BD09_MC = 6，百度bd09墨卡托坐標
         * COORDINATES_MAPBAR = 7，mapbar地圖坐標
         * COORDINATES_51 = 8，51地圖坐標
        */
      } else {
        var myGeo = new BMap.Geocoder()
        myGeo.getPoint(this.currentItem.address, (point) => {
          if (point) {
            map.centerAndZoom(point, 15)
            map.addOverlay(new BMap.Marker(point, { title: this.currentItem.address }))
          }
        }, '香港')
      }
    },
    getCurrentItem () {
      console.log('@207', this.clickedItem)
      let result = { 'all': [] }
      if (this.clickedItem) {
        console.log('100---- result: ', this.clickedItem)
        return this.clickedItem
      }

      // clicked item not found, page refreshed
      console.log('@82------ query', this.$route.query)
      const { storeId, campaignId, couponId } = this.$route.query
      if (campaignId) {
        const campaginData = this.$store.getters['campaigns/campaignDetail'](campaignId)
        if (campaginData && campaginData.detail && campaginData.detail.stores) {
          const campaignStore = campaginData.detail.stores.filter(item => {
            console.log('item .id', item.id, storeId)
            return parseInt(item.id) === parseInt(storeId)
          })
          console.log('campmaign data:', campaginData, campaignStore)
          result = campaignStore && campaignStore.length ? this.formatApplicableStore(campaignStore[0]) : {}
        }
      } else if (couponId) {
        const couponData = this.$store.getters['coupons/couponDetail'](couponId)
        if (couponData && couponData.template && couponData.template.applicable_stores) {
          const couponStore = couponData.template.applicable_stores.filter(item => {
            return parseInt(storeId) === item.id
          })
          console.log(' coupon store: ', couponStore)
          result = couponStore && couponStore.length ? this.formatApplicableStore(couponStore[0]) : {}
        }
      }

      console.log('100---- result: ', result)
      return result
    },
    baiduMapKey () {
      return process.env.VUE_APP_BAIDU_MAP_KEY
    },
    formatPhone (phone) {
      let result = phone
      if (!result) {
        return result
      }
      if (!phone.startsWith('+')) {
        result = '+' + phone
      }
      if (result.startsWith('+852') || result.startsWith('+853')) {
        result = result.slice(0, 4) + ' ' + result.slice(4, 8) + ' ' + result.slice(8)
      } else if (result.startsWith('+86')) {
        console.log(result.slice(0, 5), result.slice(4, 9), result.slice(8))
        result = result.slice(0, 3) + ' ' + result.slice(3)
      }
      return result
    },
    goToMap () {
      if (isWechatMiniProgram()) {
        return
      }
      const address = this.currentItem.address
      console.log('103------- address', address)
      /*
      setTimeout(() => {
        window.location = `http://api.map.baidu.com/geocoder?address=${address}&output=html`
      }, 25)
      */
      // const newWindow = window.open(`bdapp://map/geocoder?address=${address}&src=andr.baidu.openAPIdemo`)
      let url
      if (this.lang === 'zh-s') {
        // const client = this.judgeClient()
        /*
        if (client === 'iOS') {
          url = `baidumap://map/geocoder?address=${address}&src=ios.baidu.openAPIdemo`
        } else if (client === 'Android') {
          url = `bdapp://map/geocoder?address=${address}&src=andr.baidu.openAPIdemo`
        }
        */
        if (this.bmLat && this.bmLng) {
          url = 'http://api.map.baidu.com/marker?location=' + this.bmLat + ',' + this.bmLng + '&output=html'
        } else {
          url = `https://map.baidu.com/search/${address}/`
        }
      } else {
        console.log('@293', this.currentItem)

        url = 'https://maps.google.com/maps?q=' + encodeURI(this.googleMapQueryString)
      }

      if (url) { window.open(url) }
    },
    // initBaiduMap (address) {
    //  var map = new window.BMapGL.Map('baidu-container')
    //  map.centerAndZoom(new window.BMapGL.Point(116.331398, 39.897445), 12)
    //  var myGeo = new window.BMapGL.Geocoder()
    //  myGeo.getPoint(address, function (point) {
    //    if (point) {
    //      map.centerAndZoom(point, 16)
    //      map.addOverlay(new window.BMapGL.Marker(point, { title: address }))
    //    } else {
    //      console.log('您选择的地址没有解析到结果！')
    //    }
    //  })
    // },
    listItemClick (item, index) {
      const { campaignId, couponId } = this.$route.query
      if (campaignId) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'campaign|store detail', { campaignId, storeId: item.id })
      } else if (couponId) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'coupon|store detail', { couponId, storeId: item.id })
      }

      if (window.$('.is-vue-mobile').length) {
        this.updateLastApplicateStoreState()

        pushRouterWithQuery(this.$router, { name: 'applicable-store-detail', query: { couponId: this.couponId, campaignId: this.campaignId, storeId: item.id }})
      } else {
        this.clickedItem = item
        this.$emit('pagetypechange', 'detail')
        // this.lang = this.$i18n.locale
        // if (this.lang === 'zh-s') {
        //  setTimeout(() => { this.initBaiduMap(item.address) }, 0)
        // }
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.applicable-stores-detail::-webkit-scrollbar, .applicable-stores-list .infinite-list::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}
.applicable-stores-detail::-webkit-scrollbar-thumb, .applicable-stores-list .infinite-list::-webkit-scrollbar-thumb{
  border-radius: 1em;
  background-color: rgba(50,50,50,.3);
}
.applicable-stores-detail::-webkit-scrollbar-track, .applicable-stores-list .infinite-list::-webkit-scrollbar-track{
  border-radius: 1em;
  background-color: rgba(50,50,50,0);
}
.is-vue-desktop .applicable-stores-list{
  height: 370px;
  .top-select{
    margin-left: -10px;
    margin-right: -10px;
  }
}
.is-vue-mobile .applicable-stores-list{
  .top-select{
    padding-left: 13px;
    padding-right: 13px;
  }
}
.applicable-stores-list{
  cursor: pointer;
  word-break: break-word;
  .top-select{
    height: 63px;
    background: #F7F7F7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;

    .list-select{
      margin-left: 6px;
      margin-right: 6px;
      width: 50%;
      border: 1px solid #EEEEEE;
      box-shadow: 0px 1px 4px rgba(175, 175, 175, 0.2);
      border-radius: 4px;
    }
  }
  .infinite-list{
    overflow-y: auto;
    height: calc(100% - 63px);
  }
  .applicable-stores-name{
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    display: flex;
  }
  .applicable-stores-left-text{
    margin-right: 20px;
  }
  .applicable-stores-phone, .applicable-stores-address{
    margin-top: 3px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #747474;
    img{
      margin-right: 12px;
      display: flex;
      flex: none;
    }
  }
  .applicable-stores-phone img{
    margin-right: 10px;
  }
  .applicable-stores-line{
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;
    transition: 0.4s;
  }
  .applicable-stores-line:nth-child(n+2){
    border-top: solid 1.5px #F5F5F5;;
  }
  .applicable-stores-line:hover .applicable-stores-name{
    color: #E2002C;
  }
  .right{
    height: 11px;
    width: 8px;
    margin-top: 3px;
    margin-left: 7px;
  }
}

.applicable-stores-detail{
  word-break: break-word;
  .applicable-stores-title{
    font-weight: bold;
    color: #000000;
  }
  .applicable-stores-line{
    display: flex;
    flex-flow: row;
    align-items:flex-start;
    .text{
      padding-right: 32px;
    }
    .bold{
      font-weight: 600;
    }
  }
  .applicable-stores-line img{
    margin-top: 3px;
    margin-right: 12px;
  }
  .applicable-stores-line .right{
    margin-left: 8px;
    margin-top: 7px;
  }
  #baidu-container{
    width: 100%;
    height: 334px;
    margin-top: 10px;
  }
  .mobile-line{
    color: #D1D1D1;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .google-container{
    width: 100% ;
  }
  .mapouter{
    width: 100%;
  }
  .gmap_canvas{
    width: 100%;
  }
  iframe{
    width: 100%;
  }
  .business-hours{
    display: flex;
    flex-flow: row;
    margin-top: 4px;
    justify-content: space-between;
  }
  a{
    color: unset;
  }
  .address{
    cursor: pointer;
  }

  .weekdays{
    max-width: 66%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}

.applicable-stores-detail .store-description{
  color: #404042 !important;
}

.is-vue-desktop .applicable-stores-detail{
  height: 370px;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-flow: column;
  .mobile-line{
    display: none;
  }

  .applicable-stores-line{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 23px;
    color: #404040;
  }
  .desktop_hide{
    display: none;
  }
  .applicable-stores-title{
    font-size: 20px;
    margin-bottom: 8px;
  }
  .business-hours-contain{
    width: 55%;
  }
  a{
    color: unset;
  }
  .address .text{
    padding-right: 0px;
  }
}

.is-vue-mobile .applicable-stores-detail{
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  .applicable-stores-title{
    margin-top: 12px;
    font-size: 18px;
  }

  .store-description {
    word-break: break-word;
  }
  .right {
    right: 13px;
    position: absolute;
  }
  .applicable-stores-line{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 22px;
    color: #404042;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 23px;
    border-bottom: 1px solid #D1D1D1;
  }

  .applicable-stores-line:last-child {
    border-bottom: 0px;
  }
  .address{
    border-bottom: none;
    padding-bottom: 0px;
  }
  .business-hours{
    font-weight: normal;
  }
  .business-hours-contain{
    width: 100%;
    margin-right: 12px;
    overflow: hidden;
  }
}
.document.document-Preview-test{
  .applicable-stores-list{
    .top-select{
      background: #fff;
      margin-bottom: 8px;
      padding-left: 9px;
      padding-right: 9px;
    }
    .applicable-stores-line{
      background: #F2F3F6;
      margin-bottom: 12px;
      padding: 12px;
      margin-right: 16px;
      margin-left: 16px;
    }
    .applicable-stores-address{
      margin-top: 8px;
    }
    .applicable-stores-address img{
      display: none;
    }
    .applicable-stores-phone img{
      display: none;
    }
  }
}
</style>
<style>
.document-applicable-store-list .list-select input, .applicable-stores-dialog .list-select input{
  color: #E2002C;
  font-weight: 600;
  font-size: 14px;
}
.document-applicable-store-list .default.list-select input, .applicable-stores-dialog .default.list-select input{
  color: #999999;
}
</style>
