<template>
  <div class="search-empty-result">
    <img
      class="reward-noresult-icon"
      srcset="/img/reward-noresult.1x.png, /img/reward-noresult.2x.png 2x, /img/reward-noresult.3x.png 3x"
      src="/img/reward-noresult.2x.png"
    >
    <label class="search-no-result-title">{{ $t('searchNoResultTitle') }}</label>
    <label class="search-no-result-content">{{ $t('searchNoResultContent') }}</label>
  </div>
</template>

<script>
export default {
  name: 'SearchCampaignNoResult'
}
</script>

<style lang="scss">
.search-empty-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: Open Sans;
  color: #000;

  img {
    width: 68.323px;
    height: 66.976px;
  }

  .search-no-result-title {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    margin: 8px auto;
  }

  .search-no-result-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.5px;
  }
}

</style>
