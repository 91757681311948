const goodieBagCampaignType = ['CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG', 'GIFT_CAMPAIGN_FOR_GOODIE_BAG']

export const campaigns = {
  namespaced: true,
  state: {
    campaigns: [],
    campaignDetailList: [],
    categories: [],
    brands: [],
    offerTypeLabels: [],
    goodieBagCampaignCountRefs: {},
    campaignLimits: {},
    locations: []
  },
  mutations: {
    initCategories (state, categories) {
      state.categories = categories
    },
    initBrands (state, brands) {
      state.brands = brands
    },
    initLocations (state, locations) {
      state.locations = locations
    },
    initOfferTypeLabels (state, offerTypeLabels) {
      state.offerTypeLabels = offerTypeLabels
    },
    clearCampaigns (state) {
      state.campaigns = [
        ...state.campaigns.filter((item) => goodieBagCampaignType.includes(item.type))
      ]
    },
    clearGoodieBagCampaigns (state) {
      state.campaigns = [
        ...state.campaigns.filter((item) => !goodieBagCampaignType.includes(item.type))
      ]
    },
    initCampaigns (state, campaigns) {
      const campaignsRefs = {}
      campaigns.forEach(campaign => { campaignsRefs[`campaign-${campaign.id}`] = campaign })
      state.campaigns = [
        ...state.campaigns.filter((item) => goodieBagCampaignType.includes(item.type)),
        ...Object.values(campaignsRefs)
      ]
      // console.log('initCampaigns', campaigns.map(item => item.id), Object.values(campaignsRefs).map(item => item.id))
    },
    initGoodieBagCampaign (state, detail) {
      const { count, goodieBagCampaigns } = detail
      state.campaigns = [
        ...state.campaigns.filter((item) => !goodieBagCampaignType.includes(item.type)),
        ...goodieBagCampaigns
      ]
      state.goodieBagCampaignCountRefs = {
        'all': count
      }
    },
    setCampaignLimits (state, data) {
      state.campaignLimits = data
    },
    updateTopOfferCampaigns (state, topOfferCampaigns) {
      // TODO: update top offer campaigns
    },
    updateGoodieBagCampaign (state, detail) {
      const { goodieBagId, goodieBagCampaigns, count } = detail
      state.campaigns = [
        ...state.campaigns.filter((item) => {
          const isGoodieBagCampaign = goodieBagCampaignType.includes(item.type)
          return !isGoodieBagCampaign || (isGoodieBagCampaign && !item.goodie_bag === goodieBagId)
        }),
        ...goodieBagCampaigns
      ]
      state.goodieBagCampaignCountRefs = {
        ...state.goodieBagCampaignCountRefs,
        [goodieBagId]: count
      }
    },

    updateCampaignDetail (state, info) {
      const { id, detail } = info

      console.log('@60@14', detail)

      /*
      let campaignIndex = -1

      state.campaigns.forEach((item, index) => {
        console.log('@819', item.id, id)
        if (parseInt(item.id) === parseInt(id)) {
          console.log('@8323, match found', index)
          if (campaignIndex < 0) { campaignIndex = index }
        }
      })
      */

      detail.detail = JSON.parse(JSON.stringify(detail)) // for second level access

      const campaignIndex = state.campaignDetailList.findIndex(item => parseInt(item.id) === parseInt(id))
      console.log('@817', id, campaignIndex, state.campaignDetailList)
      if (campaignIndex > -1) {
        // const oldCampaign = state.campaignDetailList[campaignIndex]
        // const newCampaign = JSON.parse(JSON.stringify(oldCampaign))
        const newCampaign = detail
        console.log('@831', campaignIndex, newCampaign)
        state.campaignDetailList.splice(campaignIndex, 1, newCampaign)
        // console.log('@832', state.campaigns)
      } else {
        console.log('@38, added campaign', detail)
        const campaign = detail
        state.campaignDetailList.push(campaign)
      }

      const campaignIndex2 = state.campaigns.findIndex(item => parseInt(item.id) === parseInt(id))
      if (campaignIndex2 > -1) {
        // const oldCampaign = state.campaignDetailList[campaignIndex]
        // const newCampaign = JSON.parse(JSON.stringify(oldCampaign))
        const newCampaign = detail
        console.log('@831', campaignIndex2, newCampaign)
        state.campaigns.splice(campaignIndex2, 1, newCampaign)
        // console.log('@832', state.campaigns)
      } else {
        console.log('@38, added campaign', detail)
        const campaign = detail
        state.campaigns.push(campaign)
      }
    }
  },
  getters: {
    campaigns (state) {
      return state.campaigns
    },
    categories (state) {
      return state.categories
    },
    brands (state) {
      return state.brands
    },
    locations (state) {
      return state.locations
    },
    offerTypeLabels (state) {
      return state.offerTypeLabels
    },
    getCampaignLimits (state) {
      return state.campaignLimits
    },
    campaignList: (state) => {
      const displayType = ['COUPON_CAMPAIGN', 'GENERAL_MESSAGE_CAMPAIGN']
      return state.campaigns.filter(campaign => displayType.includes(campaign.type))
    },
    topOfferCampaginList: (state) => {
      const displayType = ['COUPON_CAMPAIGN', 'GENERAL_MESSAGE_CAMPAIGN']
      return state.campaigns.filter(campaign => displayType.includes(campaign.type))
    },
    allGoodieBagCampaignList (state, testerPreview) {
      return state.campaigns.filter((item) => (item.is_visible || testerPreview) && !item.is_redirection_campaign && goodieBagCampaignType.includes(item.type))
    },
    goodieBagCampaignCount: (state) => (goodieBagId) => {
      const count = state.goodieBagCampaignCountRefs[goodieBagId]
      return count || 0
    },
    goodieBagCampaignList: (state) => (goodieBagId, type, testerPreview, slotId) => {
      const result = []
      const campaignDict = {}
      state.campaigns
        // .filter(item => item.type === type && item.goodie_bag === goodieBagId)
        // .filter(item => item.type === type)
        .filter((campaign) => {
          let isShow = false
          if (campaign.goodie_bag === goodieBagId && campaign.type === type) {
            campaign.goodie_bag_campaign_slots.forEach((slot) => {
              if (slot.id.toString() === slotId) {
                isShow = true
                return
              }
            })
          }
          return isShow
        })
        .forEach(item => {
          if (!campaignDict[item.id]) {
            campaignDict[item.id] = true
            if ((item.is_visible || testerPreview) && !item.is_redirection_campaign) { result.push(item) }
          } else {
            console.log('duplicated item id', item.id)
          }
        })
      console.log('goodieBagCampaignList', goodieBagId, type)
      return result
    },
    campaignDetail: (state) => (id) => {
      const campaignDetail = state.campaignDetailList.find(campaign => parseInt(campaign.id) === parseInt(id))
      // console.log('@60, campaign detail', campaignDetail)
      if (campaignDetail) {
        return campaignDetail
      }

      const campaign = state.campaigns.find(campaign => parseInt(campaign.id) === parseInt(id))
      // console.log('@68, campaign detail - list item', campaign)

      return campaign
    }
  }
}

export default campaigns
