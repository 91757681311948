<template>
  <div class="acquires-credits-success">
    <img class="top-img" src="/img/qrcode_success.png">
    <div class="success-text">
      <div v-if="type === 'CASH_VOUCHER'" class="success">{{ $t('goodiebagSuccessfullyAcquired1') }}<b>{{ count }}</b>{{ $t('goodiebagSuccessfullyAcquired2') }}</div>
      <div v-else class="success">{{ $t('goodiebagSuccessfullyAcquired1') }}<b>{{ count }}</b>{{ $t('goodiebagSuccessfullyAcquired4') }}</div>
    </div>
    <div v-if="name" class="name">{{ name }}</div>
    <div v-if="goodiecreditsorquota && goodiecreditsorquota!=='0'" class="button-contain">
      <div class="button confirm primary-button" @click="close">{{ $t('goodiebagOk') }}</div>
      <div class="button wallet secondary-button" @click="goToMyWallt">{{ $t('goodiebagCheckmyWallet') }}</div>
    </div>
    <div v-else class="button-contain">
      <div class="button confirm primary-button" @click="goToHome">{{ $t('goodiebagBrowseOtherOffers') }}</div>
      <div class="button wallet secondary-button" @click="goToMyWallt">{{ $t('goodiebagCheckmyWallet') }}</div>
    </div>
    <hr v-if="banners.length">
    <div v-else class="no-banner-bottom" />
    <div v-if="banners && banners.length" class="carousel-container">
      <div class="text">{{ $t('goodiebagSsomeOffers') }}:</div>
      <agile-banner
        class="carousel-item"
        :banners="banners"
        :height="$store.getters['app/isMobile']?112:122"
        :width="$store.getters['app/isMobile']?292:784"
      />
    </div>
  </div>
</template>
<script>
import agileBanner from '@/components/AgileBanner'
import { sendManualAnalytics } from '@/utils/tracking'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'AcquiresCreditsSuccess',
  inject: ['closeGoodieBagSuccess'],
  components: {
    agileBanner
  },
  props: {
    count: {
      type: [String, Number],
      description: 'Count',
      default: '1'
    },
    type: {
      type: [String],
      description: 'type',
      default: 'CASH_VOUCHER'
    },
    name: {
      type: [String],
      description: 'name',
      default: ''
    },
    goodiecreditsorquota: {
      type: [String, Number],
      description: 'goodieCreditsOrQuota',
      default: 0
    },
    coupondisplayin: {
      type: [String],
      description: 'coupondisplayin',
      default: ''
    }
  },
  computed: {
    banners () {
      const banners = this.$store.getters['app/getPromptBanners']
      return banners && banners.length > 0 ? banners.slice(0, 5) : []
    }
  },
  methods: {
    close () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'redemption_successfully_ok', 'onclick')
      this.closeGoodieBagSuccess()
    },
    goToMyWallt () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'redemption_successfully_checkmywallet', 'onclick')
      if (this.coupondisplayin === 'VOUCHER_SECTION') {
        pushRouterWithQuery(this.$router, { path: 'my-voucher' })
      } else if (this.coupondisplayin === 'COUPON_SECTION') {
        pushRouterWithQuery(this.$router, { path: 'my-coupons' })
      } else {
        console.log('@84')
        pushRouterWithQuery(this.$router, { path: 'my-tickets' })
      }
    },
    goToHome () {
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'redemption_successfully_browseroffer', 'onclick')
      pushRouterWithQuery(this.$router, { path: 'home' })
    }
  }
}
</script>
<style lang="scss" scoped>
.acquires-credits-success-page{
    .acquires-credits-success{
        .success-text{
          b{
            font-weight: normal;
          }
        }
        .name{
          font-weight: bold;
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 30px;
          margin-left: 56px;
          margin-right: 56px;
        }
        .top-img{
            width: 134px;
            margin-top: 30px;
        }
        .success{
            font-size: 15px;
            margin-bottom: 10px;
            margin-top: 17px;
            line-height: 23px;
        }
        .count{
            font-size: 20px;
            margin-bottom: 30px;
            margin-top: 3px;
        }
        .button-contain{
            flex-flow: column;
            .button.wallet{
                margin-top: 15px;
            }
            .button.confirm{
                padding-left: 20px;
                padding-right: 20px;
            }
            .button.wallet{
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        hr{
            width: calc(100% - 106px);
            margin-top: 29px;
            margin-bottom: 30px;
        }
        .carousel-container{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-bottom: 40px;
            .text{
                font-size: 18px;
                margin-bottom: 20px;
            }
            .carousel-item{
                width: calc(100% - 94px);
            }
        }
    }
}
.acquires-credits-success{
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .no-banner-bottom{
      height: 24px;
    }
    .success-text{
      display: flex;
      align-items: center;
      justify-content: center;
      b{
        font-weight: 600;
      }
    }
    .name{
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 24px;
      margin-left: 24px;
      margin-right: 24px;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    .top-img{
        width: 103px;
        margin-top: 22px;
    }
    .success{
        font-size: 14px;
        color: #000000;
        margin-bottom: 16px;
        margin-top: 16px;
    }
    .button-contain{
        display: flex;
        flex-flow: column;
        .button{
            font-weight: bold;
            font-size: 18px;
            display: flex;
            align-items: center;
            text-align: center;
        }
        .button.confirm{
            border-radius: 50px;
            border:none;
        }
        .button.wallet{
            border-radius: 50px;
            margin-top: 12px;
        }
    }
    hr{
        width: calc(100% - 46px);
        margin-top: 24px;
        margin-bottom: 16px;
    }
    .carousel-container{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 16px;
        width: 100%;
        .text{
            font-size: 14px;
            color: #000000;
            text-align: center;
        }
        .carousel-item{
            margin-bottom: 20px;
            margin-top: 16px;
            cursor: pointer;
        }
    }
}
</style>
