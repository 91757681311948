<template>

  <component
    :is="item.coupon_campaign_type_coupon_stock > 0 && !isExpired && !isShowOutOfStockLabel? 'router-link' : 'div'"
    tag="div"
    :to="$i18nRoute($route, 'campaign-detail', null, {recordId: $route.query.recordId, campaignId: item.id, slotId: slotid, from: 'list'}, {from: 'list'})"
    :class="[
      'item-detail',
      {'is-safari': isSafari || isWechatMiniProgram},
      // {featured: item.is_featured && item.coupon_campaign_type_coupon_stock > 0},
      {gift: item.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'},
      {'is-starred': item.is_starred}
    ]
    "
  >
    <div class="featured-label">{{ $t('goodiebagFeatured') }}</div>
    <div
      v-lazy-container="{ selector: 'img.lazy-load' }"
      :class="['item-frame',
               {'selectable-card':item.coupon_campaign_type_coupon_stock > 0 && !isExpired && !isShowOutOfStockLabel},
               {'active': item.coupon_campaign_type_coupon_stock > 0 && !isExpired && !isShowOutOfStockLabel}]"
    >
      <div class="item-banner-container">
        <div class="item-banner">

          <img
            :is="item.cover_photo_url ? 'img' : 'div'"
            :data-src="item.cover_photo_url"
            class="item-banner-imgage lazy-load"
          />

        </div>
        <div v-if="item.logo_labels && item.logo_labels.length > 0" class="item-logo-container">
          <component
            :is="logo.icon_url ? 'img' : 'div'"
            v-for="logo in item.logo_labels.slice(0, 3)"
            :key="logo.id"
            class="item-logo"
            :src="logo.icon_url || false"
          />
        </div>
        <div v-if="isShowOutOfStockLabel" class="low-label"><div>{{ $t('campaignOutOf') }}</div></div>
        <div v-else-if="item.show_low_stock_label" class="low-label"><div>{{ $t('campaignLow') }}</div></div>
      </div>
      <div v-if="item.is_starred" class="coupon_campaign_type"><div>{{ $t('campaignSTARRED') }}</div></div>
      <div v-else class="no_campaign_type" />
      <div
        ref="title"
        class="item-title"
      >
        <!--<div v-if="expandTitle" class="item-title-placehoder-empty" />
        <div v-if="expandTitle" class="item-title-placehoder" />-->
        {{ item.name }}
      </div>

      <div class="item-detail-container">
        <div class="item-detail-left-container">
          <div />
          <div>
            <div v-if="item.end_date" class="item-valid-date">
              {{ validTil }}
            </div>
            <div v-else class="item-valid-date" />

            <div class="item-brand-info">
              <div
                v-if="item.brand"
                class="item-brand-icon-container"
              >
                <img
                  class="item-brand-icon"
                  :src="item.brand.icon_url"
                >
              </div>
              <div
                v-if="item.brand"
                class="item-brand-name"
              >
                {{ item.brand.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.type === 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG'"
          class="item-credit-cost-container"
        >
          <div class="item-credit-cost">{{ item.goodie_bag_reward }}</div>
          <div class="item-credit-cost-unit">{{ $t('goodiebagCredits') }}</div>
          <img
            class="bg"
            srcset="/img/voucher-cost-cog.1x.png, /img/voucher-cost-cog.2x.png 2x, /img/voucher-cost-cog.3x.png 3x"
            src="/img/voucher-cost-cog.2x.png"
          >
          <img
            class="bg desktop"
            srcset="/img/voucher-cost-cog-desktop.1x.png, /img/voucher-cost-cog-desktop.2x.png 2x, /img/voucher-cost-cog-desktop.3x.png 3x"
            src="/img/voucher-cost-cog-desktop.2x.png"
          >
        </div>
        <div v-else class="item-credit-cost-container is-empty" />
      </div>
    </div>
    <div
      v-if="(item.offer_type_label && item.offer_type_label.name) ||
        isExpired"
      class="flyer"
    >
      <img
        class="bg"
        srcset="/img/flyer-bg.1x.png, /img/flyer-bg.2x.png 2x, /img/flyer-bg.3x.png 3x"
        src="/img/flyer-bg.2x.png"
      >
      <div class="place-holder" />
      <div class="triangle-bottomright" />
      <div
        v-if="isExpired"
        class="flyer-text"
      >
        {{ $t('goodiebagExpired') }}
      </div>
      <!--<div
        v-else-if="item.coupon_campaign_type_coupon_stock <= 0"
        class="flyer-text"
      >
        {{ $t('campaignOutOf') }}
      </div>-->
      <div
        v-else-if="item.offer_type_label && item.offer_type_label.name"
        class="flyer-text"
      >
        {{ item.offer_type_label.name }}
      </div>
    </div>
    <div v-if="item.coupon_campaign_type_coupon_stock <= 0 || isExpired || isShowOutOfStockLabel" class="out-of-stock-overlay" />
  </component>
</template>
<script>
import { isWechatMiniProgram } from '@/utils/utils'

export default {
  name: 'CashVoucherListItem',
  props: {
    item: {
      type: Object,
      default: null
    },
    recordEndDate: {
      type: String,
      default: null
    },
    slotid: {
      type: [Number, String],
      description: 'slotId',
      default: null
    },
    goodieBag: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      expandTitle: false
    }
  },
  computed: {
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    isSafari () {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    },
    validTil () {
      const {
        end_date: campaginEndDate,
        is_follow_goodie_bag_active_period: followGoodieBagActivePeriod
      } = this.item
      return this.getCampaignVaidTil(this.recordEndDate, campaginEndDate, followGoodieBagActivePeriod)
    },
    isExpired () {
      return this.item.is_expired
    },
    isShowOutOfStockLabel () {
      return this.item.show_out_of_stock_label
    }
  },
  mounted () {
    this.checkTitleHeight()
  },
  methods: {
    checkTitleHeight () {
      if (this.$refs.title) {
        const { clientHeight } = this.$refs.title
        console.log('check title height', clientHeight)
        this.expandTitle = clientHeight >= 50
      }
    }
  }}
</script>

<style lang="scss" scoped>

.is-vue-desktop {
  .item-detail {
    width: 241px;
    // height: 335px;
    margin-left: 5px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;

    &.featured {
      width: 236px;
      margin-top: 0px;
    }

    .item-logo-container{
      bottom: -18px;
      right: 6.5px;
      .item-logo {
        width: 27.5px;
        height: 27.5px;
      }
    }

    .item-title{
      font-weight: 600;
      font-size: 15px;
      height: 72px;
      line-height: 24px;
    }

    .item-valid-date{
      margin-bottom: 12.5px;
      font-size: 11px;
      color: #999999;
      line-height: 15px;
    }

    .item-brand-info {
      .item-brand-name {
        font-size: 13px;
        line-height: 18px;
        word-break: break-all;
      }

      .item-brand-icon-container {
        width: 20px;
        height: 20px;
        flex: none;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    .item-credit-cost-container{
      width: 60px;
      height: 60px;

      .item-credit-cost{
        font-size: 18px;
        line-height: 25px;
      }

      .item-credit-cost-unit{
        font-size: 10px;
        line-height: 14px;
      }

      .bg {
        display: none;

        &.desktop {
          display: block;
        }
      }
    }

  }
  .coupon_campaign_type{
      font-weight: 700;
      font-size: 10px;
      height: 18px;
      margin-top: 8px;
  }
  .no_campaign_type{
      height: 26px;
  }

}

.document { /* both desktop & mobile share same logic */
  .goodie-bag-main-container {  /* 2022-10-25 */
    .item-detail {
      .item-logo-container{
        bottom: -8px
      }

      .item-detail-container{
        margin-top: 10px;
      }
      .item-detail-left-container{
        padding: 0;
      }

      .item-title{
        margin-top: 16px;
        line-height: 20px;
        margin-bottom: 24px;
        height: 60px;
      }

      .item-valid-date {
        margin-bottom: 8px;
      }
    }

    .item-detail.is-starred .item-title {
      margin-top: 4px;
      height: 60px;
      margin-bottom: 0;
    }
    .no_campaign_type {
      display: none;
      height: 0px;
    }

    .coupon_campaign_type {
      margin-left: 10px;
    }

  }
}

.coupon_campaign_type{
  border: 1.5px solid #FF580A;
  border-radius: 3px;
  height: 22px;
  font-weight: 700;
  font-size: 10px;
  color: #FF580A;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  margin-top: 12px;
  margin-right: 12px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
}

.no_campaign_type{
  height: 26px;
}

.low-label{
    left: 0;
    bottom: 0;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 20px;
    background: #05218A;
    border-radius: 0px 10px 0px 0px;
    div{
        font-weight: 700;
        font-size: 12px;
        color: #FFFFFF;
        text-transform: uppercase;
        margin-left: 6px;
        margin-right: 6px;
    }
}

.is-vue-mobile .low-label{
  height: 30px;
  div{
    font-size: 16px;
    margin-left: 7px;
    margin-right: 7px;
  }
}

.is-safari {
  .item-title {
    margin-bottom: 5px;
    color: red;

    .item-title-placehoder-empty, .item-title-placehoder {
      display: none;
    }
  }
}

.item-detail{
  position: relative;
  margin-left: 11px;
  padding-left: 5px;
  margin-right: 19px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.featured.gift {
    .item-frame {
      border: 5px solid #68CFBD;
    }
    .featured-label {
      background: #68CFBD;
    }
  }

  &.featured {
    margin-left: 6px;
    margin-right: 14px;

    .featured-label {
      display: block;
    }
    .item-frame{
      border-top-left-radius: 0px;
      border: 5px solid #E87A3C
    }
    .flyer {
      top: 50px;
    }
  }

  .featured-label{
    display: none;
    background: #E87A3C;
    padding-top: 6px;
    padding-bottom: 2px;
    padding-left: 16px;
    padding-right: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }

  .out-of-stock-overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F7F7F7;
    opacity: 0.6;
    overflow: visible;
    pointer-events: none;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
  }

  .item-detail-container{
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 12px;

    .item-detail-left-container{
      flex: 1;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .item-credit-cost-container{
    width: 78px;
    height: 78px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .item-credit-cost{
      z-index: 1;
      font-weight: bold;
      font-size: 21.6px;
      line-height: 29px;
      color: #FFFFFF;
    }

    .item-credit-cost-unit{
      margin-top: -5px;
      margin-right: -3px;
      margin-bottom: 5px;
      z-index: 1;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
    }

    .bg {
      position: absolute;
      object-fit: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &.desktop {
        display: none;
      }
    }
  }

  .item-credit-cost-container.is-empty{
    width: 1px;
  }

  .item-frame{
    background: #FFFFFF;
    // box-shadow: 0px 1px 4px rgba(179, 179, 179, 0.25);
    box-shadow: 0px 1px 6px hsla(0, 0%, 70%, 0.5);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    height: 100%;

    &.active {
      cursor: pointer;
    }
  }

  .item-title {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 42px;
    max-height: 75px;

    .item-title-placehoder-empty {
      height: 50px;
      width: 0px;
      float: right;
    }

    .item-title-placehoder {
      height: 25px;
      width: 78px;
      float: right;
      clear: right;
    }
  }

  .item-valid-date{
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 16px;
  }

  .item-brand-info{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .item-brand-icon-container{
      width: 32px;
      height: 31px;
      border-radius: 16px;
      overflow: hidden;
      flex: none;

      img {
        object-fit: cover;
        height: 31px;
        width: 32px;
      }
    }

    .item-brand-name{
      font-size: 14px;
      line-height: 19px;
      color: #000000 ;
      margin-left: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .item-logo-container{
    position: absolute;
    bottom: -23px;
    right: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .item-logo {
      margin: 5px;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      object-fit: cover;
      box-shadow: 0px 1.4614px 5.8456px rgba(61, 61, 61, 0.5);
    }
  }

  .flyer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 20px;
    height: 36px;
    padding-left: 14px;
    padding-top: 5px;
    padding-right: 20px;

    .place-holder {
      position: absolute;
      height: 31px;
      right: 0;
      top: 5px;
      bottom: 0;
      left: 0;
      margin-right: 50px;
      background-color: #FE4534;
    }

    .triangle-bottomright {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-bottom: 5px solid #A60101;
      border-left: 5px solid transparent;
    }

    .flyer-text{
      z-index: 1;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }

    .bg {
      position: absolute;
      height: 31px;
      right: 0;
      top: 5px;
    }
  }

  .item-banner-container {
    position: relative;
    flex: 1;

    .item-banner {
      position: relative;
      width: 100%;
      padding-bottom: 75%;

      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>
