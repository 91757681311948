<template>
  <div class="logout" />
</template>
<script>
// import { getSsoAuthClient } from '@/helpers/http'
import { doLogout } from '@/helpers/http'
export default {
  name: 'Logout',
  data: function () {
    return {

    }
  },
  async mounted () {
    const { redirectUrl: redirectUrl } = this.$route.query
    const dhkDomain = process.env.VUE_APP_DEALS_EN_URL ? process.env.VUE_APP_DEALS_EN_URL.replace('https://', '').split('/')[0] : ''
    const fromReload = !(dhkDomain && redirectUrl && redirectUrl.includes(dhkDomain))
    await doLogout(redirectUrl, true, false, true, fromReload)
  },
  beforeDestroy () {

  },
  methods: {
  }
}
</script>
<style scoped lang='scss'>
</style>
