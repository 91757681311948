export const local = {
  namespaced: true,
  state: {
    addToHomeScreenTimestamp: 0,
    isLanguageChecked: false,
    isGotCookiePolicy: false,
    isTutorialShowed: false,
    isLanguageReminderShown: false
  },
  mutations: {
    setAddToHomeScreenTimestamp (state, timestamp) {
      state.addToHomeScreenTimestamp = timestamp
      console.log('@3123', state.addToHomeScreenTimestamp)
    },
    markLanguageChecked (state) {
      state.isLanguageChecked = true
    },
    clear (state) {
      state.isLanguageChecked = false
    },
    gotCookiePolicy (state) {
      state.isGotCookiePolicy = true
    },
    tutorialShowed (state) {
      state.isTutorialShowed = true
    },
    isLanguageReminderShown (state) {
      state.isLanguageReminderShown = true
    }
  },
  getters: {
    getAddToHomeScreenTimestamp (state) {
      return state.addToHomeScreenTimestamp
    },
    getIsGotCookiePolicy (state) {
      return state.isGotCookiePolicy
    },
    getIsTutorialShowed (state) {
      return state.isTutorialShowed
    },
    getIsLanguageReminderShown (state) {
      return state.isLanguageReminderShown
    }
  }
}

export default local
