<template>
  <div id="scan-qr-code">
    <div class="qr-code-full-window">
      <div class="qr-contain full-width">
        <div class="full-width close-contain">
          <img class="close" src="/img/scan_close.png" width="40px" height="40px" @click="close()">
        </div>
        <div class="title-contain">
          SCAN
        </div>
        <div class="title-contain">
          STORE QR CODE
        </div>
        <div class="description-contain">
          Align QR Code within frame to scan.
        </div>
      </div>
      <div id="qr-code-full-region" />
    </div>
  </div>
</template>
<script>
// import Html5QrcodeScanner from 'html5-qrcode'
import { sendManualAnalytics } from '@/utils/tracking'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'ScanQRCode',
  components: {
  },
  data () {
    return {
      html5Qrcode: ''
    }
  },
  mounted () {
    const config = {
      fps: 10,
      qrbox: 250,
      aspectRatio: 1.777778
    }
    this.html5Qrcode = new window.Html5Qrcode('qr-code-full-region')
    this.html5Qrcode.start(
      { facingMode: 'environment' }, config, this.onScanSuccess, this.onScanError
    )
  },
  methods: {
    onScanSuccess (qrCodeMessage) {
      // this.html5Qrcode.stop()
      console.log(qrCodeMessage)
    },
    onScanError (errorMessage) {
      this.error = errorMessage

      // below not yet tested
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
        'coupon_camera_fail', 'event', { camera_errormsg: JSON.stringify(errorMessage) })
    },
    close () {
      this.html5Qrcode.stop()
      pushRouterWithQuery(this.$router, { name: 'coupon-detail' })
    }
  }
}
</script>
<style scoped lang='scss'>
#scan-qr-code{
  .qr-code-full-window {
    position: fixed !important;
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    background-color: #7B7B7B;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  #qr-code-full-region {
    width: 100%;
    position: fixed !important;
  }
  .full-width{
    width: 100%;
  }
  .close-contain{
    justify-content: flex-end;
    display: flex;
    margin-bottom: -18px;
  }
  .close-contain img{
    height: 40px;
  }
  .close{
    float: right;
    margin-right: 21px;
    margin-top: 19px;
  }
  .title-contain{
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 28px;
  }
  .description-contain{
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    padding-left: 28px;
    margin-top: 12px;
  }
  .qr-contain{
    z-index: 40;
  }
}
</style>
